import dateRangePresets from "@/helper/date/range";

const defaultBoard = () => {
  return {
    clusters: [],
    currency: "GBP",
    excludedCampaigns: [],
    filters: {
      dateRange: dateRangePresets.range({ type: "last30Day" }),
      domain: [],
      excludedClients:[], // for debug
      geo: [],
      includePublishers: false,
      industryScope: {
        level: "all",
        name: "All",
        code: 0,
      },
    },
    name: 'Unsaved Topic Board',
    topics: [],
    uuid: 'new',
  }
};

const state = { board: defaultBoard() };

const getters = {
  getBoard(){
    return state.board;
  },
  getClusters: (state) => {
    return state.board.clusters;
  },
  getDateRange: (state) => {
    return state.board.filters.dateRange;
  },
  getTopics: (state) => {
    return state.board.topics;
  },
};

const actions = {
  setBoard({ commit }, board){
    commit("setBoard", board)
  },
  setDefault: ({ commit }) => {
    commit("setDefault");
  },
  setClusters: ({ commit }, clusters) => {
    commit("setClusters", clusters);
  },
  setDateRange: ({ commit }, dateRange) => {
    commit("setDateRange", dateRange);
  },
  setTopics: ({ commit }, topics) => {
    commit("setTopics", topics);
  },
};

const mutations = {
  setBoard(state, board){
    state.board = {...state.board, ...board}
  },
  setDefault: (state) => {
    state.board = defaultBoard();
  },
  setClusters: (state, clusters) => {
    state.board.clusters = clusters;
  },
  setDateRange: (state, dateRange) => {
    const board = structuredClone(state.board);
    board.filters.dateRange = dateRange;
    state.board = board;
  },
  setTopics: (state, topics) => {
    state.board.topics = topics;
  },
};

export default {
  actions,
  getters,
  mutations,
  namespaced: true,
  state,
};
