import Api from "../connect/api";
import {defaultApiParams} from "../helper/store";

let sneakyDataStore = {};

const NOT_CALLED_STATE = "API~NOT_CALLED~STATE",
      LOADING_STATE = "API~LOADING~STATE",
      SUCCESS_STATE = "API~SUCCESS~STATE",
      FAILED_STATE = "API~FAILED~STATE";

const apiStateCallback = (commit, key, status, data = null) => {
  sneakyDataStore[key] = data;
  commit("setReport", {key, status});
};

const apiGetState = (url, commit, state, key) => {
  if (state[key] !== NOT_CALLED_STATE) return;
  apiStateCallback(commit, key, LOADING_STATE);
  Api.get(url,
    response => apiStateCallback(commit, key, SUCCESS_STATE, response),
    () => apiStateCallback(commit, key, FAILED_STATE),
  );
};

const apiPostState = (url, data, commit, state, key) => {
  if (state[key] !== NOT_CALLED_STATE) return;
  apiStateCallback(commit, key, LOADING_STATE);
  Api.post(url, data,
    response => apiStateCallback(commit, key, SUCCESS_STATE, response),
    () => apiStateCallback(commit, key, FAILED_STATE),
  );
};

const getReport = (state, key) => {
  switch (state[key]) {
    case LOADING_STATE:
    case NOT_CALLED_STATE:
      return null;
    case FAILED_STATE:
      return false;
    case SUCCESS_STATE:
      return sneakyDataStore[key];
  }
};

const getDefaultState = () => {
  return {
    listKeyEvent: NOT_CALLED_STATE,
    reportTagChannel: NOT_CALLED_STATE,
    reportTagEvent: NOT_CALLED_STATE,
    reportTagFirmographic: NOT_CALLED_STATE,
    reportTagFirmographicCountry: NOT_CALLED_STATE,
    reportTagFirmographicIndustry: NOT_CALLED_STATE,
    reportTagFirmographicOverviewCompany: NOT_CALLED_STATE,
    reportTagFirmographicOverviewRiser: NOT_CALLED_STATE,
    reportTagTraffic: NOT_CALLED_STATE,
    reportTagTrafficPreviousYear: NOT_CALLED_STATE,
  };
};

// initial state
const state = getDefaultState();

const getters = {
  getListKeyEvent: (state) => getReport(state, "listKeyEvent"),
  getReportTagChannel: (state) => getReport(state, "reportTagChannel"),
  getReportTagEvent: (state) => getReport(state, "reportTagEvent"),
  getReportTagFirmographic: (state) => getReport(state, "reportTagFirmographic"),
  getReportTagFirmographicCountry: (state) => getReport(state, "reportTagFirmographicCountry"),
  getReportTagFirmographicIndustry: (state) => getReport(state, "reportTagFirmographicIndustry"),
  getReportTagFirmographicOverviewCompany: (state) => getReport(state, "reportTagFirmographicOverviewCompany"),
  getReportTagFirmographicOverviewRiser: (state) => getReport(state, "reportTagFirmographicOverviewRiser"),
  getReportTagTraffic: (state) => getReport(state, "reportTagTraffic"),
  getReportTagTrafficPreviousYear: (state) => getReport(state, "reportTagTrafficPreviousYear"),
};

const actions   = {
  resetState({commit}) {
    commit("resetState");
  },
  lookupKeyEvent({commit, rootGetters}, reFetch = false) {
    const key = "listKeyEvent";
    if (reFetch) {
      commit("setReport", {key, status: NOT_CALLED_STATE});
    }
    apiGetState(
      `key/event?mode=OR&all=true&aaUuid=${rootGetters["alphixCollection/getCollectionSetting"]?.aa_uuid?.join(",")}&clientType=${rootGetters["alphix/getClient"].clientType}`,
      commit,
      state,
      key,
    );
  },
  lookupReportTagChannel({commit, rootGetters, state}) {
    apiPostState(
      `tag/reporting/channel`,
      defaultApiParams(rootGetters),
      commit,
      state,
      "reportTagChannel",
    );
  },
  lookupReportTagEvent({commit, rootGetters, state}) {
    apiPostState(
      `tag/reporting/event`,

      defaultApiParams(rootGetters),
      commit,
      state,
      "reportTagEvent",
    );
  },
  lookupReportTagFirmographic({commit, rootGetters, state}) {
    apiPostState(
      `tag/reporting/firmographic`,
      {
        ...defaultApiParams(rootGetters),
        previousCompare: true,
      },
      commit,
      state,
      "reportTagFirmographic",
    );
  },
  lookupReportTagFirmographicOverviewCompany({commit, rootGetters, state}) {
    apiPostState(
      `tag/reporting/firmographic/company`,
      {
        ...defaultApiParams(rootGetters),
        previousCompare: true,
      },
      commit,
      state,
      "reportTagFirmographicOverviewCompany",
    );
  },
  lookupReportTagFirmographicOverviewRiser({commit, rootGetters, state}) {
    apiPostState(
      `tag/reporting/firmographic/riser`,
      {
        ...defaultApiParams(rootGetters),
        previousCompare: true,
      },
      commit,
      state,
      "reportTagFirmographicOverviewRiser",
    );
  },
  lookupReportTagFirmographicCountry({commit, rootGetters, state}) {
    apiPostState(
      `tag/reporting/firmographic/country`,
      defaultApiParams(rootGetters),
      commit,
      state,
      "reportTagFirmographicCountry",
    );
  },
  lookupReportTagFirmographicIndustry({commit, rootGetters, state}) {
    apiPostState(
      `tag/reporting/firmographic/industry`,
      defaultApiParams(rootGetters),
      commit,
      state,
      "reportTagFirmographicIndustry",
    );
  },
  lookupReportTagTraffic({commit, rootGetters, state}) {
    apiPostState(
      `tag/reporting/traffic`,

      defaultApiParams(rootGetters),
      commit,
      state,
      "reportTagTraffic",
    );
  },
  lookupReportTagTrafficPreviousYear({commit, rootGetters, state}) {
    apiPostState(
      `tag/reporting/traffic`,
      {
        ...defaultApiParams(rootGetters),
        startDate: rootGetters["alphixCollection/getDateRangePreviousYear"].start,
        endDate: rootGetters["alphixCollection/getDateRangePreviousYear"].end,
      },
      commit,
      state,
      "reportTagTrafficPreviousYear",
    );
  },
  /**
   * lookupReportTagPage
   * lookupReportTagPagePreviousPeriod
   * lookupReportTagPagePreviousYear
   */

};
const mutations = {
  resetState(state) {
    Object.assign(state, getDefaultState());
    sneakyDataStore = {};
  },
  setReport(state, {key, status}) {
    state[key] = status;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
