import dateRange from "@/helper/date/range";
import Api from "../connect/api";
import router from "../router";
import Vue from "vue";

const getDefaultState = () => {
  return {
    collection: null,
    collectionList: null,
    collectionSetting: null,
    collectionUuid: null,
    dateRange: null,
    dimensionList: null,
    report: null,
    reportList: null,
    reportSetting: null,
    reportUuid: null,
    route: null,
  };
};

const UNFILTERED_COLLECTION = "unfiltered";

// initial state
const state = getDefaultState();

const getters = {
  getCollection: (state) => {
    if (state.collectionUuid === UNFILTERED_COLLECTION) {
      return {
        name: "Unfiltered",
        uuid: UNFILTERED_COLLECTION,
        settings: {},
      };
    } else if (state.collectionList === null) {
      return null;
    } else {
      const collection = state.collectionList.find(c => c.uuid === state.collectionUuid);
      return collection || false;
    }
  },
  getCollectionList: (state) => {
    return state.collectionList;
  },
  getCollectionSetting: (state, getters) => {
    if (!getters.getCollection) {
      return {};
    }

    return getters.getCollection.settings;
  },
  getCollectionUuid: (state) => {
    return state.getCollectionUuid;
  },
  getDateRange: (state) => {
    return state.dateRange;
  },
  getDimensionList: (state) => {
    return state.dimensionList;
  },
  getReport: (state) => {
    return state.report;
  },
  getReportList: (state) => {
    return state.reportList;
  },
  getReportSetting: (state) => {
    return state.reportSetting;
  },
  getReportUuid: (state) => {
    return state.reportUuid;
  },
  isMissing: (state, getters) => {
    return state.report === false || getters.getCollection === false;
  },
  isReady: (state, getters) => {
    if (getters.getCollection === null || state.report === null || state.reportSetting === null || state.dateRange === null || state.route === null) {
      return false;
    }
    if (state.report.uuid !== state.route.params.reportUuid || getters.getCollection.uuid !== state.route.params.collectionUuid) {
      return false;
    }
    return true;
  },
};

const actions   = {
  lookupCollectionList({commit, getters}) {
    if (getters.getCollectionList === null) {
      commit("setCollectionList", null);
      Api.get(
          `alphalytics/collection`,
          collectionList => {
            commit("setCollectionList", collectionList);
          },
          () => {
            commit("setCollectionList", []);
          },
      );
    }
  },
  lookupDimensionList({commit, getters}) {
    if (getters.getDimensionList === null) {
      let list = [
        {
          text: "Page",
          value: "page",
        },
        {
          text: "Domain",
          value: "domain",
        },
        {
          text: "Raw Url",
          value: "url",
        },
        {
          text: "Firm",
          value: "company",
        },
        {
          text: "Firm Country",
          value: "officeCountry",
        },
        {
          text: "Firm Town",
          value: "officeTown",
        },
        {
          text: "Firm Size",
          value: "firmoSize",
        },
        {
          text: "Industry",
          value: "industry",
        },
        {
          text: "Browser",
          value: "browser",
        },
        {
          text: "Device",
          value: "device",
        },
        {
          text: "Operating System",
          value: "os",
        },
        {
          text: "Country",
          value: "country",
        },
      ];

      if (this.getters['alphix/getClientPaidMedia']) {
        list.push(
          {
            text: "Paid Media Booking",
            value: "paid_media_booking",
          },
          {
            text: "Paid Media Creative",
            value: "paid_media_creative",
          },
          {
            text: "Paid Media Site",
            value: "paid_media_site",
          }
        );
      }

      Api.post(
      `tag/reporting/dimension/list`,
        {
          domainId: getters.getReport.defaultCollectionDomain?.length ? JSON.parse(getters.getReport.defaultCollectionDomain) : [],
          endDate: getters.getDateRange.end,
          startDate: getters.getDateRange.start,
        },
        (customFilterList) => {
          for (const customFilter of customFilterList) {
            list.push({
              text: customFilter,
              value: `custom-${customFilter}`,
            });
          }
          commit("setDimensionList", list);
        },
        () => {
          commit("setDimensionList", []);
        }
      );
    }
  },
  lookupReport({commit, getters}) {
    if (getters.getReport === null) {
      commit("setReport", null);
      Api.get(
          `alphalytics/report/${getters.getReportUuid}`,
          report => {
            commit("setReport", report);
            commit("setDimensionList", null);
          },
          () => {
            commit("setReport", false);
          },
          () => {
            Vue.notify({
              title: "API call error",
              text:
                  "Try refreshing your browser and if the problem persists contact support.",
              type: "error"
            });
          },
          () => {
            router.push({
              name: `analytics-report-list`,
            });
          },
      );
    }
  },
  lookupReportList({commit, getters}, reload = false) {
    if (getters.getReportList === null || reload) {
      commit("setReportList", null);
      Api.get(
          `alphalytics/report`,
          reportList => {
            commit("setReportList", reportList);
          },
          () => {
            commit("setReportList", []);
          },
      );
    }
  },
  lookupReportSetting({commit, dispatch, getters}, reload = false) {
    if (getters.getReportSetting === null || reload) {
      commit("setReportSetting", null);
      Api.get(
          `alphalytics/report/setting/${getters.getReportUuid}`,
          settings => {
            dispatch("alphixReportData/resetState", null, {root: true});
            commit("setReportSetting", settings);
          },
          () => {
            commit("setReportSetting", {});
          },
      );
    }
  },
  setRoute({
             commit,
             dispatch,
             state,
           }, route) {
    if (!route.params?.reportUuid || !route.params?.collectionUuid || !route.params?.dateRange) {
      commit("resetState");
      dispatch("alphixReportData/resetState", null, {root: true});
      router.push({
        name: `analytics-report-list`,
      });
      return;
    }

    let resetData = false;
    if (state.reportUuid !== route.params.reportUuid) {
      commit("resetState");
      commit("setReportUuid", route.params.reportUuid);
      resetData = true;
    }
    commit("setRoute", route);

    if (state.collectionUuid !== route.params.collectionUuid) {
      commit("setCollectionUuid", route.params.collectionUuid);
      resetData = true;
    }

    const [type, start, end] = route.params.dateRange.split("~");
    if (!state.dateRange || state.dateRange.type !== type || (type === 'custom' && (state.dateRange.start !== start || state.dateRange.end !== end))) {
      commit("setDateRange",
          dateRange.range({
            type,
            start,
            end,
          }),
      );
      resetData = true;
    }

    if (resetData) {
      dispatch("alphixReportData/resetState", null, {root: true});
    }

    dispatch("lookupReport");
    dispatch("lookupReportSetting");
    dispatch("lookupCollectionList");
  },
};
const mutations = {
  resetState(state) {
    const newState = {
      ...getDefaultState(),
    };

    Object.assign(state, newState);
  },
  setCollection(state, collection) {
    state.collection = collection;
  },
  setCollectionList(state, collectionList) {
    state.collectionList = collectionList;
  },
  setCollectionSetting(state, collectionSetting) {
    state.collectionSetting = collectionSetting;
  },
  setCollectionUuid(state, collectionUuid) {
    state.collectionUuid = collectionUuid;
  },
  setDateRange(state, dateRange) {
    state.dateRange = dateRange;
  },
  setDimensionList(state, dimensionList) {
    state.dimensionList = dimensionList;
  },
  setReport(state, report) {
    state.report = report;
  },
  setReportList(state, reportList) {
    state.reportList = reportList;
  },
  setReportSetting(state, reportSetting) {
    state.reportSetting = reportSetting;
  },
  setReportUuid(state, reportUuid) {
    state.reportUuid = reportUuid;
  },
  setRoute(state, route) {
    state.route = route;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
