export const getLoginRoute = (saveCurrentLocation = true, path = '') => {
  const domain = location.hostname.match(/[^.]*.(.*)/); //removes client name prefix from location
  const returnPath = saveCurrentLocation ? `?returnPath=${location.href}` : '';

  switch (domain?.[1]) {
    case 'alphix.local':
      return `http://login.alphix.local:8069/${path}${returnPath}`;
    case 'alphix-alpha.com':
      return `https://login.alphix-alpha.com/${path}${returnPath}`;
    case 'alphix-beta.com':
      return `https://login.alphix-beta.com/${path}${returnPath}`;
    case 'alphix-demo.com':
      return `https://login.alphix-demo.com/${path}${returnPath}`;
    case 'alphix-release.com':
      return `https://login.alphix-release.com/${path}${returnPath}`;
    case 'alphix-staging.com':
      return `https://login.alphix-staging.com/${path}${returnPath}`;
    case 'alphix.com':
    default:
      return `https://login.alphix.com/${path}${returnPath}`;
  }
}

export default {
  getLoginRoute
}
