<template>
  <transition
    name="slide-fade"
    mode="out-in"
  >
    <div>
      <div class="sNavLeftMenuHolder d-flex flex-column">
        <left-menu-header />
        <div
          v-show="activeMenu && Object.keys(activeMenu).length"
          class="sPageLeftMenu"
        >
          <div class="sClientSelector">
            <page-header-client />
          </div>

          <page-header-left-menu-navigation
            v-for="topLevelSection of activeMenu.sections"
            v-show="topLevelSection.authShow"
            :ref="topLevelSection.nameRef"
            :key="topLevelSection.title"
            class="sSectionGroup"
            :topLevelSection="topLevelSection"
            @expanded="closeSection(topLevelSection.nameRef)"
          />

        </div>

        <v-spacer />
        <left-menu-footer
          :force-menu-mode="menuMode"
          :show-admin="adminMenu.sections[0].authShow"
          :show-configure="configureMenu.sections[0].authShow"
          @menu-mode-updated="updateMenuMode"
        />
      </div>
    </div>
  </transition>
</template>

<script>
import { mapGetters } from "vuex";
import { topLevelSections } from "@/data/navigation/sections/mainNavigation.js";
import { adminTopLevelSections } from "@/data/navigation/sections/adminNavigation.js";
import { configureTopLevelSections } from "@/data/navigation/sections/configureNavigation.js";
import LeftMenuFooter from "@/components/Page/Header/LeftMenu/Footer.vue";
import LeftMenuHeader from "@/components/Page/Header/LeftMenu/Header.vue";
import PageHeaderLeftMenuNavigation from "@/components/Page/Header/LeftMenu/Navigation.vue";
import PageHeaderClient from "@/components/Page/Header/Client";

export default {
  name: "PageHeaderLeftMenu",
  components: {
    LeftMenuFooter,
    LeftMenuHeader,
    PageHeaderLeftMenuNavigation,
    PageHeaderClient,
  },
  props: {
    forceMenuMode: {
      type: String,
      default: "topLevelSections",
    },
  },
  data() {
    return {
      menuMode: this.forceMenuMode,
    }
  },
  computed: {
    ...mapGetters('alphix'),
    ...mapGetters('alphixCollection', ['getCollection', 'getCollectionSetting']),
    activeMenu() {
      if (this.menuMode === "adminTopLevelSections") {
        return this.adminMenu;
      }
      if (this.menuMode === "configureTopLevelSections") {
        return this.configureMenu;
      }
      return this.mainMenu;
    },
    adminMenu() {
      return this.authSections(adminTopLevelSections);
    },
    configureMenu() {
      return this.authSections(configureTopLevelSections);
    },
    mainMenu() {
      return this.authSections(topLevelSections);
    },
    routeNameHierarchy() {
      if (!this.$route || !this.$route.name) {
        return [];
      }
      return this.$route.name.split("-");
    },
  },
  watch: {
    menuMode(value) {
      if (value === "topLevelSections" && this.routeNameHierarchy[0] !== 'analytics') {
        this.$router.push({
          name: 'analytics-overview',
          params: {
            acUuid: this.$route.params.acUuid || 'unfiltered',
            dateRange: this.$route.params.dateRange || 'last30Day'
          }
        });
      }
      else if (value === "adminTopLevelSections" && this.routeNameHierarchy[0] !== 'admin') {
        this.$router.push({ name: 'admin-subscriptions-users' });
      }
      else if (value === "configureTopLevelSections" && this.routeNameHierarchy[0] !== 'configure') {
        this.$router.push({ name: 'configure-firmographic-groups' });
      }
    },
    $route: {
      handler: "checkRouteHierarchy",
      immediate: true,
    }
  },
  methods: {
    authLinks(links) {
      let allLinksFail = true;
      for (const link of links) {
        if (link.links) {
          link.authShow = this.authLinks(link.links);
        }
        else if (link.fAllowedPrivilege?.length) {
          link.authShow = this.fAllowed(link.fAllowedPrivilege);
        }
        else {
          link.authShow = true;
        }
        if (link.authShow) {
          allLinksFail = false;
        }
      }
      return !allLinksFail;
    },
    authSections(nav) {
      for (const section of nav.sections) {
        section.authShow = this.authLinks(section.links);
      }
      return nav;
    },
    checkRouteHierarchy() {
      if (this.routeNameHierarchy[0] === "analytics") {
        this.menuMode = "topLevelSections";
      }
      else if (this.routeNameHierarchy[0] === "admin") {
        this.menuMode = "adminTopLevelSections";
      }
      else if (this.routeNameHierarchy[0] === "configure") {
        this.menuMode = "configureTopLevelSections";
      }
    },
    closeSection(ignoreNameRef) {
      for (const topLevelSection of this.activeMenu.sections) {
        if (topLevelSection.nameRef !== ignoreNameRef) {
          this.$refs[topLevelSection.nameRef][0].close();
        }
      }
    },
    updateMenuMode(mode) {
      this.menuMode = mode;
    }
  }
};
</script>

<style
  scoped
  lang="scss"
>
.sNavLeftMenuHolder {
  top: 0;
  width: 100%;
  min-width: 250px;
  height: 100vh;
  position: -webkit-sticky;
  position: sticky;
  background: #FFFFFF;
  border-right: 1px solid #D8D8D8;
  z-index: 101;
  flex-grow: 1;
}

.sPageLeftMenu {
  width: 100%;
  max-height: calc(100vh - 165px);
  overflow-x: hidden;
  overflow-y: scroll;
  padding: 8px 3px 16px 19px;
  position: -webkit-sticky;
  position: sticky;

  &::-webkit-scrollbar {
    background-color: transparent;
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: transparent;
    border-radius: 20px;
    border: 5px solid #DEE2EB;
    height: 10px;
  }

  .sClientSelector {
    padding-bottom: 24px;
  }
}
</style>
