import Vue from "vue";
import Vuex from "vuex";
import alphix from "./store/alphix";
import alphixCollection from "@/store/alphixCollection";
import alphixData       from "@/store/alphixData";
import alphixReport       from "@/store/alphixReport";
import alphixReportData    from "@/store/alphixReportData";
import firmographicGroup from "@/store/firmographicGroup";
import marketingCollection from "@/store/marketingCollection";
import marketingData from "@/store/marketingData";
import subroute from "@/store/subroute";
import trends from "@/store/trends";
import trendsBoard from "@/store/trendsBoard";

Vue.use(Vuex);

const state = {};
const getters = {};
const actions = {};
const mutations = {};

export const store = new Vuex.Store({
  modules: {
    alphix,
    alphixCollection,
    alphixData,
    alphixReport,
    alphixReportData,
    firmographicGroup,
    marketingCollection,
    marketingData,
    subroute,
    trends,
    trendsBoard,
  },
  state,
  getters,
  actions,
  mutations
});
