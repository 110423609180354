import moment from "moment";

const range = date => {
  if (date.type === "custom") {
    return {
      type: date.type,
      start: new Date(date.start).toISOString().substring(0, 10),
      end: new Date(date.end).toISOString().substring(0, 10)
    };
  } else {
    for (const item of rangeList(moment().utc(true).toISOString().substring(0, 10))) {
      if (item.id === date.type) {
        return {
          type: date.type,
          start: new Date(item.start).toISOString().substring(0, 10),
          end: new Date(item.end).toISOString().substring(0, 10)
        };
      }
    }
  }

  // Assume corrupted date object
  return {
    type: date.type,
    start: new Date(date.start).toISOString().substring(0, 10),
    end: new Date(date.end).toISOString().substring(0, 10)
  };
};
// date object example for comparisonRange
// { start: "2020-03-10", end: "2020-03-15", type: "weekOnWeek" };
const comparisonRange = date => {
  for (const item of comparisonRangeList(date.start, date.end, date.type)) {
    if (item.id === date.type) {
      return {
        type: item.id,
        start: new Date(item.start).toISOString().substring(0, 10),
        end: new Date(item.end).toISOString().substring(0, 10)
      };
    }
  }
};

const comparisonRangeList = (start, end, format) => {
  if (start === undefined || end === undefined) return [];
  let comparisonRangePresets = [];
  const diffStartEnd = Math.abs(moment(start).diff(moment(end), "days")) + 1;
  start = moment(start).utc(true);
  end = moment(end).utc(true);

  [
    {
      id: "prevPeriod",
      label: "Previous Period",
      isValid: () => diffStartEnd >= 0,
      start: moment(start)
        .utc(true)
        .subtract(diffStartEnd, "days"),
      end: moment(end)
        .utc(true)
        .subtract(diffStartEnd, "days")
    },
    {
      // filter if the difference between the start and and date is less than 8 days
      id: "weekOnWeek",
      label: "Week on week",
      isValid: () =>
        diffStartEnd >= 0 && diffStartEnd < 7 && start.week() === end.week(),
      start: moment(start)
        .utc(true)
        .subtract(1, "week"),
      end: moment(end)
        .utc(true)
        .subtract(1, "week")
    },
    {
      // filter if the difference between the start and and date is less than 32 days
      id: "monthOnMonth",
      label: "Month on month",
      isValid: () =>
        diffStartEnd >= 0 && diffStartEnd < 32 && start.month() === end.month(),
      start: moment(start)
        .utc(true)
        .subtract(1, "month"),
      end:
        moment(end)
          .utc(true)
          .date() ===
        moment(end)
          .utc(true)
          .endOf("month")
          .date()
          ? moment(end)
              .utc(true)
              .subtract(1, "month")
              .endOf("month")
          : moment(end)
              .utc(true)
              .subtract(1, "month")
    },
    {
      // filter if the difference between the start and and date is less than 100 day and are in the same quarter
      id: "quarterOnQuarter",
      label: "Quarter on quarter",
      isValid: () =>
        diffStartEnd >= 0 &&
        diffStartEnd < 100 &&
        start.quarter() === end.quarter(),
      start: moment(start)
        .utc(true)
        .subtract(1, "quarters"),
      end:
        moment(end)
          .utc(true)
          .date() ===
        moment(end)
          .utc(true)
          .endOf("month")
          .date()
          ? moment(end)
              .utc(true)
              .subtract(1, "quarter")
              .endOf("month")
          : moment(end)
              .utc(true)
              .subtract(1, "quarter")
    },
    {
      // filter if the difference between the start and and date is less than
      // 200 days and both quarters are <= 2 or >2
      id: "halfOnHalf",
      label: "Half on half",
      isValid: () =>
        diffStartEnd >= 0 &&
        diffStartEnd < 200 &&
        Math.round(start.quarter() / 2) === Math.round(end.quarter() / 2),
      start: moment(start)
        .utc(true)
        .subtract(2, "quarters"),
      end:
        moment(end)
          .utc(true)
          .date() ===
        moment(end)
          .utc(true)
          .endOf("month")
          .date()
          ? moment(end)
              .utc(true)
              .subtract(2, "quarters")
              .endOf("month")
          : moment(end)
              .utc(true)
              .subtract(2, "quarters")
    },
    {
      // filter if the difference between the start and and date is less than
      // 367 days and both dates are in the same year <= 2 or >2
      id: "yearOnYear",
      label: "Year on year",
      isValid: () => diffStartEnd >= 0 && diffStartEnd < 367,
      start: moment(start)
        .utc(true)
        .subtract(1, "year"),
      end:
        moment(end)
          .utc(true)
          .date() ===
        moment(end)
          .utc(true)
          .endOf("month")
          .date()
          ? moment(end)
              .utc(true)
              .subtract(1, "year")
              .endOf("month")
          : moment(end)
              .utc(true)
              .subtract(1, "year")
    }
  ].forEach(date => {
    if (date.isValid())
      comparisonRangePresets.push({
        id: date.id,
        label: date.label,
        start: date.start.toISOString().substring(0, 10),
        end: date.end.toISOString().substring(0, 10),
        formattedLabel: format
          ? `${date.label} (${moment(date.start).format(format)}
            - ${moment(date.end).format(format)})`
          : `${date.label} (${date.start
              .toISOString()
              .substring(0, 10)} - ${date.end.toISOString().substring(0, 10)})`
      });
  });

  return comparisonRangePresets;
};

const rangeList = currentDate => {
  let rangePresets = [];
  [
    {
      id: "yesterday",
      label: "Yesterday",
      dateRange: () => ({
        start: moment(currentDate)
          .utc(true)
          .subtract(1, "days"),
        end: moment(currentDate)
          .utc(true)
          .subtract(1, "days")
      })
    },
    {
      id: "weekToDate",
      label: "Week to Date",
      dateRange: () => ({
        start: moment(currentDate)
          .utc(true)
          .subtract(1, "days")
          .startOf("week")
          .day(1),
        end: moment(currentDate).utc(true)
      })
    },
    {
      id: "monthToDate",
      label: "Month to Date",
      dateRange: () => ({
        start: moment(currentDate)
          .utc(true)
          .startOf("month"),
        end: moment(currentDate).utc(true)
      })
    },
    {
      id: "quarterToDate",
      label: "Quarter to Date",
      dateRange: () => ({
        start: moment(currentDate)
          .utc(true)
          .startOf("quarter"),
        end: moment(currentDate).utc(true)
      })
    },
    {
      id: "yearToDate",
      label: "Year to Date",
      dateRange: () => ({
        start: moment(currentDate)
          .utc(true)
          .startOf("year"),
        end: moment(currentDate).utc(true)
      })
    },
    {
      id: "prevWeek",
      label: "Previous Week",
      // retrieves the previous week's Monday
      dateRange: () => ({
        start: moment(currentDate)
          .utc(true)
          .subtract(1, "days")
          .subtract(1, "weeks")
          .day(1),
        // retrieves the previous week's Sunday
        end: moment(currentDate)
          .utc(true)
          .subtract(1, "days")
          .day(0)
      })
    },
    {
      id: "prevMonth",
      label: "Previous Month",
      dateRange: () => ({
        start: moment(currentDate)
          .utc(true)
          .subtract(1, "months")
          .startOf("month"),
        end: moment(currentDate)
          .utc(true)
          .subtract(1, "months")
          .endOf("month")
      })
    },
    {
      id: "prev3Month",
      label: "Previous 3 Months",
      dateRange: () => ({
        start: moment(currentDate)
          .utc(true)
          .subtract(3, "months")
          .startOf("month"),
        end: moment(currentDate)
          .utc(true)
          .subtract(1, "months")
          .endOf("month")
      })
    },
    {
      id: "prev6Month",
      label: "Previous 6 Months",
      dateRange: () => ({
        start: moment(currentDate)
          .utc(true)
          .subtract(6, "months")
          .startOf("month"),
        end: moment(currentDate)
          .utc(true)
          .subtract(1, "months")
          .endOf("month")
      })
    },
    {
      id: "prevQuarter",
      label: "Previous Quarter",
      dateRange: () => ({
        start: moment(currentDate)
          .utc(true)
          .startOf("quarter")
          .subtract(1, "quarter"),
        end: moment(currentDate)
          .utc(true)
          .subtract(1, "quarter")
          .endOf("quarter")
      })
    },
    {
      id: "prevYear",
      label: "Previous Year",
      dateRange: () => ({
        start: moment(currentDate)
          .utc(true)
          .startOf("year")
          .subtract(1, "year"),
        end: moment(currentDate)
          .utc(true)
          .subtract(1, "year")
          .endOf("year")
      })
    },
    {
      id: "last7Day",
      label: "Previous 7 Days",
      dateRange: () => ({
        start: moment(currentDate)
          .utc(true)
          .subtract(7, "days"),
        end: moment(currentDate)
          .utc(true)
          .subtract(1, "days")
      })
    },
    {
      id: "last14Day",
      label: "Previous 14 Days",
      dateRange: () => ({
        start: moment(currentDate)
          .utc(true)
          .subtract(14, "days"),
        end: moment(currentDate)
          .utc(true)
          .subtract(1, "days")
      })
    },
    {
      id: "last30Day",
      label: "Previous 30 Days",
      dateRange: () => ({
        start: moment(currentDate)
          .utc(true)
          .subtract(30, "days"),
        end: moment(currentDate)
          .utc(true)
          .subtract(1, "days")
      })
    },
    {
      id: "last60Day",
      label: "Previous 60 Days",
      dateRange: () => ({
        start: moment(currentDate)
          .utc(true)
          .subtract(60, "days"),
        end: moment(currentDate)
          .utc(true)
          .subtract(1, "days")
      })
    },
    {
      id: "last90Day",
      label: "Previous 90 Days",
      dateRange: () => ({
        start: moment(currentDate)
          .utc(true)
          .subtract(90, "days"),
        end: moment(currentDate)
          .utc(true)
          .subtract(1, "days")
      })
    },
    {
      id: "last365Day",
      label: "Previous 365 Days",
      dateRange: () => ({
        start: moment(currentDate)
          .utc(true)
          .subtract(365, "days"),
        end: moment(currentDate)
          .utc(true)
          .subtract(1, "days")
      })
    }
  ].forEach(date => {
    rangePresets.push({
      id: date.id,
      label: date.label,
      start: date
        .dateRange()
        .start.toISOString()
        .substring(0, 10),
      end: date
        .dateRange()
        .end.toISOString()
        .substring(0, 10)
    });
  });
  return rangePresets;
};

export default { comparisonRangeList, comparisonRange, rangeList, range };
