import Api from "../connect/api";

const getDefaultState = () => {
  return {
    onehelp: { name: 'home'},
  };
};

// initial state
const state = getDefaultState();

const getters = {
  getOnehelp: function() {
    return state.onehelp;
  },
};

const actions = {
  routeOnehelp({ commit }, route) {
    route = typeof route === "string" ? { name: route } : route;
    commit("setOnehelp", route);
  },
};

const mutations = {
  setOnehelp(state, name) {
    state.onehelp = name;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
