import Api from "../connect/api";

let sneakyDataStore = {};

const NOT_CALLED_STATE = "API~NOT_CALLED~STATE",
      LOADING_STATE = "API~LOADING~STATE",
      SUCCESS_STATE = "API~SUCCESS~STATE",
      FAILED_STATE = "API~FAILED~STATE";

const apiStateCallback = (commit, key, status, data = null) => {
  sneakyDataStore[key] = data;
  commit("setReport", {key, status});
};

const apiGetState = (url, commit, state, key) => {
  if (state[key] !== NOT_CALLED_STATE) return;
  apiStateCallback(commit, key, LOADING_STATE);
  Api.get(url,
    response => apiStateCallback(commit, key, SUCCESS_STATE, response),
    () => apiStateCallback(commit, key, FAILED_STATE),
  );
};

const apiPostState = (url, data, commit, state, key) => {
  if (state[key] !== NOT_CALLED_STATE) return;
  apiStateCallback(commit, key, LOADING_STATE);
  Api.post(url, data,
    response => apiStateCallback(commit, key, SUCCESS_STATE, response),
    () => apiStateCallback(commit, key, FAILED_STATE),
  );
};

const getReport = (state, key) => {
  switch (state[key]) {
    case LOADING_STATE:
    case NOT_CALLED_STATE:
      return null;
    case FAILED_STATE:
      return false;
    case SUCCESS_STATE:
      return sneakyDataStore[key];
  }
};

const getDefaultState = () => {
  return {
    reportAttributionCampaign: NOT_CALLED_STATE,
    reportAttributionCampaign12Month: NOT_CALLED_STATE,
    reportClientFlow: NOT_CALLED_STATE,
  };
};

// initial state
const state = getDefaultState();

const getters = {
  getReportAttributionCampaign: (state) => getReport(state, "reportAttributionCampaign"),
  getReportAttributionCampaign12Month: (state) => getReport(state, "reportAttributionCampaign12Month"),
  getReportClientFlow: (state) => getReport(state, "reportClientFlow"),
};

const actions   = {
  resetState({commit}) {
    commit("resetState");
  },
  lookupReportAttributionCampaign({commit, rootGetters, state}) {
    apiPostState(
      `marketing/collection/reporting/attribution/campaign`,
      {
        attributionCampaign: rootGetters["marketingCollection/getCollectionSetting"].attribution_campaign,
        currency: rootGetters["marketingCollection/getCollectionSetting"].currency,
        geoId: rootGetters["marketingCollection/getCollectionSetting"].geo_id,
        languageId: rootGetters["marketingCollection/getCollectionSetting"].language_id,
        startDate: rootGetters["marketingCollection/getDateRange"].start,
        endDate: rootGetters["marketingCollection/getDateRange"].end,
      },
      commit,
      state,
      "reportAttributionCampaign",
    );
  },
  lookupReportAttributionCampaign12Month({commit, rootGetters, state}) {
    apiPostState(
      `marketing/collection/reporting/attribution/campaign`,
      {
        attributionCampaign: rootGetters["marketingCollection/getCollectionSetting"].attribution_campaign,
        currency: rootGetters["marketingCollection/getCollectionSetting"].currency,
        geoId: rootGetters["marketingCollection/getCollectionSetting"].geo_id,
        languageId: rootGetters["marketingCollection/getCollectionSetting"].language_id,
        startDate: rootGetters["marketingCollection/getPeriod12Month"].dateStart,
        endDate: rootGetters["marketingCollection/getPeriod12Month"].dateEnd,
      },
      commit,
      state,
      "reportAttributionCampaign12Month",
    );
  },
  lookupReportClientFlow({commit, rootGetters, state}) {
    apiGetState(
      `client/flow/monthly?clientUuid=${rootGetters["alphix/getClientUuid"]}`,
      commit,
      state,
      "reportClientFlow",
    );
  },
};
const mutations = {
  resetState(state) {
    Object.assign(state, getDefaultState());
    sneakyDataStore = {};
  },
  setReport(state, {key, status}) {
    state[key] = status;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
