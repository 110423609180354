import Api from "../connect/api";
import {defaultApiParams} from "../helper/store";

let sneakyDataStore = {};

const NOT_CALLED_STATE = "API~NOT_CALLED~STATE",
      LOADING_STATE = "API~LOADING~STATE",
      SUCCESS_STATE = "API~SUCCESS~STATE",
      FAILED_STATE = "API~FAILED~STATE";

const apiStateCallback = (commit, key, status, data = null) => {
  sneakyDataStore[key] = data;
  commit("setReport", {key, status});
};

const apiGetState = (url, commit, state, key) => {
  if (state[key] !== NOT_CALLED_STATE) return;
  apiStateCallback(commit, key, LOADING_STATE);
  Api.get(url,
    response => apiStateCallback(commit, key, SUCCESS_STATE, response),
    () => apiStateCallback(commit, key, FAILED_STATE),
  );
};

const apiPostState = (url, data, commit, state, key) => {
  if (state[key] !== NOT_CALLED_STATE) return;
  apiStateCallback(commit, key, LOADING_STATE);
  Api.post(url, data,
    response => apiStateCallback(commit, key, SUCCESS_STATE, response),
    () => apiStateCallback(commit, key, FAILED_STATE),
  );
};

const getReport = (state, key) => {
  switch (state[key]) {
    case LOADING_STATE:
    case NOT_CALLED_STATE:
      return null;
    case FAILED_STATE:
      return false;
    case SUCCESS_STATE:
      return sneakyDataStore[key];
  }
};

const getDefaultState = () => {
  return {
    reportGoalFirmographicJourney: NOT_CALLED_STATE,
    reportCustomSankeyEvent: NOT_CALLED_STATE,
    reportCustomSankeyPage: NOT_CALLED_STATE,
    reportConsentManagementImpact: NOT_CALLED_STATE,
  };
};

// initial state
const state = getDefaultState();

const getters = {
  getReportGoalFirmographicJourney: (state) => getReport(state, "reportGoalFirmographicJourney"),
  getReportCustomSankeyEvent: (state) => getReport(state, "reportCustomSankeyEvent"),
  getReportCustomSankeyPage: (state) => getReport(state, "reportCustomSankeyPage"),
  getReportConsentManagementImpact: (state) => getReport(state, "reportConsentManagementImpact"),
};

const actions   = {
  resetState({commit}) {
    commit("resetState");
  },
  lookupReportConsentManagementImpact({commit, rootGetters, state}) {
    const payload = {
      filters: {
        ...defaultApiParams(rootGetters, "alphixReport"),
      },
      dimensions: {
        primary: rootGetters["alphixReport/getReportSetting"].dimensionPrimary || null,
        secondary: rootGetters["alphixReport/getReportSetting"].dimensionSecondary || null,
      },
      consentUuid: rootGetters["alphixReport/getReport"].consentUuid || null,
    };
    apiPostState(
      `tag/reporting/event/consent`,
      payload,
      commit,
      state,
      "reportConsentManagementImpact",
    );
  },
  lookupReportGoalFirmographicJourney({commit, rootGetters, state}) {
    apiPostState(
      `tag/reporting/page/firmo/journey`,
      {
        ...defaultApiParams(rootGetters, "alphixReport"),
        goalUuidList: [
          rootGetters["alphixReport/getReportSetting"].startGoal,
            ...rootGetters["alphixReport/getReportSetting"].endGoalList
        ],
      },
      commit,
      state,
      "reportGoalFirmographicJourney",
    );
  },
  lookupReportCustomSankeyEvent({commit, rootGetters, state}, eventCodeList) {
    apiPostState(
      `event/reporting/log`,
      {
        ...defaultApiParams(rootGetters, "alphixReport"),
        eventCodeList,
      },
      commit,
      state,
      "reportCustomSankeyEvent",
    );
  },
  lookupReportCustomSankeyPage({commit, rootGetters, state}, cleanIdList) {
    apiPostState(
      `tag/reporting/page/log`,
      {
        ...defaultApiParams(rootGetters, "alphixReport"),
        urlCleanIdList: cleanIdList,
      },
      commit,
      state,
      "reportCustomSankeyPage",
    );
  },
};
const mutations = {
  resetState(state) {
    Object.assign(state, getDefaultState());
    sneakyDataStore = {};
  },
  setReport(state, {key, status}) {
    state[key] = status;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
