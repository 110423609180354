import Vue               from "vue";
import App               from "./App.vue";
import router            from "./router";
import {store}           from "./store";
import vuetify           from "./plugins/vuetify";
import {fAllowed}        from "@/core/connect/mixin";
import * as Sentry       from "@sentry/vue";
import * as Integrations from "@sentry/integrations";
import Notifications     from "vue-notification";
import Fundavue          from '@fundamentalmedia/fundavue';
import VueCropper        from "vue-cropperjs";
import "cropperjs/dist/cropper.css";

if (process.env.NODE_ENV !== "development") {
  Sentry.init({
    dsn:         "https://dac8400ba30f48c2bccd251c0486e26c@o137032.ingest.sentry.io/5910561",
    environment: 'production',
    integrations(integrations) {
      integrations.push(
        new Integrations.Vue({Vue, attachProps: true, console: true})
      );
      return integrations;
    }
  });
} else {
  Vue.config.errorHandler = (err, vm, info) => {
    Vue.util.warn(`Error in ${info}: "${err.toString()}"`, vm);
  };
}

import actionLog from "./logger/action";
router.beforeEach((to, from, next) => {
    // Required because on login, before the user can select a client, it's redirected to login.

    // Prevents page from reload in order to select client
    if(from.name === null && window.location.href.includes('select-client')) return
    // Enables page to reload
    else next()
});
router.afterEach(() => {
    actionLog("page");
});

const fundavueOptions = {
  theme: "sAlphix",
}
Vue.use(Fundavue, fundavueOptions);
Vue.use(Notifications);
Vue.component("VueCropper", VueCropper);

Vue.mixin({
  methods: {
    fAllowed: fAllowed
  },
});

new Vue({
  vuetify,
  el:     "#app",
  router,
  store,
  render: h => h(App)
});
