module.exports = {
  ALPHIX: {
    code: "ALPHIX",
    title: "Alphix Solutions",
    logoUrl: "GCS URL",
    favicon: "GCS URL"
  },
  PORTAL: {
    code: "PORTAL",
    title: "Fundamental Portal",
    logoUrl: "GCS URL",
    favicon: "GCS URL"
  }
}