<template>
  <div class="sLeftMenuHeader">
    <div class="logoWrapper">
      <router-link 
        v-slot="{ navigate }"
        :to="$route.path !== '/terms' ? '/': ''" 
        :disabled="$route.path === '/terms'" 
        custom 
      >
        <div
          class="logoLink"
          role="link"
          @click="navigate"
        >
          <img
            class="logo"
            :src="`https://static.alphix.com/image/logo/Alphix-HOR-SOLUTIONS_V2.svg`"
          >
        </div>
      </router-link>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "LeftMenuHeader",
  data() {
    return {
      showMenu: false,
    }
  },
};
</script>

<style scoped lang="scss">
@import "../../../../scss/fundamental-variables.scss";

.sLeftMenuHeader {
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: solid 1px #D8D8D8;
}

.logoWrapper {
  align-self: center;
  padding: 16px 16px 16px 28px;

  .logoLink {
    height: 28px;
    cursor: pointer;
  }

  .logo {
    height: 100%;
  }
  
}

.box {
  cursor: pointer;
  height: 44px;
  display: flex;
  align-items: center;
  padding: 13px;
  margin-right: 3px;
  border-radius: 50%;

  i {
    font-size: 20px
  }

  img {
    height: 18px;
  }

  &:hover{
    background-color: #EDEFF5;
  }
}
</style>
