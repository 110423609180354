import Vue from "vue";
import Router from "vue-router";
import {store} from "./store";
import interstitialList from "@/data/Interstitial/mappings";

Vue.use(Router);

const router = new Router({
  mode: "history",
  routes: [
    {
      name: "home",
      path: "/",
      component: () => import("./views/Home.vue"),
    },
    {
      name: "activation-ai-asset",
      path: "/activation/ai/asset/:uuid",
      component: () => import("./views/Vector/Page/Asset.vue"),
    },
    {
      name: "activation-ai-asset-overview",
      path: "/activation/ai/overview/:uuid",
      component: () => import("./views/Vector/Overview.vue"),
    },

    {
      name: "activation-ai-asset-recipe",
      path: "/activation/ai/asset/recipe/:uuid",
      component: () => import("./views/Vector/Page/Recipe.vue"),
    },
    {
      name: "activation-ai-home",
      path: "/activation/ai/home",
      component: () => import("./views/Vector/Discover.vue"),
    },
    {
      name: "activation-ai-page",
      path: "/activation/ai/page",
      component: () => import("./views/Vector/URL/Active.vue"),
    },
    {
      name: "activation-ai-url",
      path: "/activation/ai/url",
      component: () => import("./views/Vector/URL.vue"),
    },
    {
      name: "analytics-audience-acquisition",
      path: "/analytics/:acUuid/:dateRange/audience-acquisition",
      component: () => import("./views/Analytics/AudienceAcquisition.vue"),
      meta: {
        allowed: "client/alphaAnalyticsView"
      }
    },
    {
      name: "analytics-bot-insight",
      path: "/analytics/:acUuid/:dateRange/bot-insight",
      component: () => import("./views/Analytics/BotInsight.vue"),
      meta: {
        allowed: "client/alphaAnalyticsView"
      }
    },
    {
      name: "analytics-events",
      path: "/analytics/:acUuid/:dateRange/events",
      component: () => import("./views/Analytics/Event.vue"),
      meta: {
        allowed: "client/alphaAnalyticsView"
      }
    },
    {
      name: "analytics-firmographic",
      path: "/analytics/:acUuid/:dateRange/firmographic",
      component: () => import("./views/Analytics/Firmographic.vue"),
      meta: {
        allowed: "client/alphaAnalyticsView"
      }
    },
    {
      name: "analytics-marketing-attribution-campaign",
      path: "/analytics/marketing/attribution/campaign",
      component: () => import("./views/Client/Attribution/Campaign.vue"),
      meta: {
        allowed: 'client/attributionCampaignView'
      }
    },
    {
      name: "analytics-marketing-attribution-campaign-detail",
      path: "/analytics/marketing/attribution/campaign/:campaignId",
      component: () => import("./views/Client/Attribution/Campaign/Detail.vue"),
      meta: {
        allowed: 'client/attributionCampaignView'
      }
    },
    {
      name: "analytics-marketing-attribution-group",
      path: "/analytics/marketing/attribution/group",
      component: () => import("./views/Client/Attribution/Group.vue"),
      meta: {
        allowed: ['client/attributionGroupView', 'client/flowView']
      }
    },
    {
      name: "analytics-marketing-attribution-group-detail",
      path: "/analytics/marketing/attribution/group/:groupId",
      component: () => import("./views/Client/Attribution/Group/Detail.vue"),
      meta: {
        allowed: ['client/attributionGroupView', 'client/flowView']
      }
    },
    {
      name: "analytics-marketing-attribution-grouping",
      path: "/analytics/marketing/attribution-grouping",
      component: () => import("./views/Marketing/AttributionGrouping.vue"),
      meta: {
        allowed: "marketing/view"
      }
    },
    {
      name: "analytics-marketing-filter",
      path: "/analytics/marketing/filter",
      component: () => import("./views/Marketing/Filter.vue"),
      meta: {
        allowed: "marketing/view"
      }
    },
    {
      name: "analytics-marketing-creative-agency",
      path: "/analytics/marketing/creative/agency",
      component: () => import("./views/Creative/Agency.vue"),
      meta: {
        allowed: "creative/agencyView"
      }
    },
    {
      name: "analytics-marketing-creative-agency-detail",
      path: "/analytics/marketing/creative/agency/:agencyId",
      component: () => import("./views/Creative/Agency/Detail.vue"),
      meta: {
        allowed: "creative/agencyView"
      }
    },
    {
      name: "analytics-marketing-creative-set",
      path: "/analytics/marketing/creative/set",
      component: () => import("./views/Creative/Set"),
      meta: {
        allowed: ["creative/setView", "creative/agencyView", "client/productView"]
      }
    },
    {
      name: "analytics-marketing-flow",
      path: "/analytics/marketing/flow",
      component: () => import("./views/Client/Flow.vue"),
      meta: {
        allowed: "client/flowView"
      }
    },
    {
      name: "analytics-marketing-flow-detail",
      path: "/analytics/marketing/flow/:flowId",
      component: () => import("./views/Client/Flow/Detail"),
      meta: {
        allowed: "client/flowView"
      }
    },
    {
      name: "analytics-marketing-product",
      path: "/analytics/marketing/product",
      component: () => import("./views/Client/Product.vue"),
      meta: {
        allowed: "client/productView"
      }
    },
    {
      name: "analytics-marketing-product-detail",
      path: "/analytics/marketing/product/:productId",
      component: () => import("./views/Client/Product/Detail.vue"),
      meta: {
        allowed: "client/productView"
      }
    },
    {
      name: "analytics-overview",
      path: "/analytics/:acUuid/:dateRange/overview",
      component: () => import("./views/Analytics/Overview.vue"),
      meta: {
        allowed: "client/alphaAnalyticsView"
      }
    },
    {
      name: "analytics-report-list",
      path: "/analytics/report",
      component: () => import("./views/Analytics/Report/List.vue"),
      meta: {
        allowed: "analytics/reportView"
      }
    },
    {
      name: "analytics-report-view",
      path: "/analytics/report/:reportUuid/:collectionUuid/:dateRange",
      component: () => import("./views/Analytics/Report/View.vue"),
      meta: {
        allowed: "analytics/reportView"
      }
    },
    {
      name: "analytics-site-engagement",
      path: "/analytics/:acUuid/:dateRange/site-engagement",
      component: () => import("./views/Analytics/SiteEngagement.vue"),
      meta: {
        allowed: "client/alphaAnalyticsView"
      }
    },
    {
      name: "analytics-site-traffic",
      path: "/analytics/:acUuid/:dateRange/site-traffic",
      component: () => import("./views/Analytics/SiteTraffic.vue"),
      meta: {
        allowed: "client/alphaAnalyticsView"
      }
    },
    {
      name: "admin-subscriptions-access-tools",
      path: "/admin/subscriptions/accessTools",
      component: () => import("./views/Client/Subscription/AccessTools.vue"),
      meta: {
        allowed: "subscription/manageAgreement"
      }
    },
    {
      name: "configure-ip-list-manage",
      path: "/configure/ipList/manage",
      component: () => import("./views/Configure/IpList/Manage.vue"),
      meta: {
        allowed: "client/manageIpList"
      }
    },
    {
      name: "admin-subscriptions-create",
      path: "/admin/subscriptions/create",
      component: () => import("./views/Client/Subscription/CreateCompany.vue"),
      meta: {
        allowed: "subscription/manageClient"
      }
    },
    {
      name: "client-subscription-home",
      path: "/client/subscription/home",
      component: () => import("./views/Client/Subscription/Home.vue"),
    },
    {
      name: "admin-subscriptions-manage",
      path: "/admin/subscriptions/manage",
      component: () => import("./views/Client/Subscription/Manage.vue"),
      meta: {
        allowed: "subscription/view"
      }
    },
    {
      name: "admin-subscriptions-users",
      path: "/admin/subscriptions/users",
      component: () => import("./views/Client/Subscription/User.vue"),
      meta: {
        allowed: "subscription/viewUser || subscription/manageUser"
      }
    },
    {
      name: "admin-tagging-activity",
      path: "/admin/tagging/activity",
      component: () => import("./views/Delta/Activity.vue"),
      meta: {
        allowed: "deltaTag/viewBilling"
      }
    },
    {
      name: "admin-tagging-configure-domains",
      path: "/admin/tagging/configure-domains",
      component: () => import("./views/Configure/Domain.vue"),
      meta: {
        allowed: "client/configureDomain"
      }
    },
    {
      name: "admin-tagging-deployment-event",
      path: "/admin/tagging/deployment/event",
      component: () => import("./views/Event/Tag.vue"),
      meta: {
        allowed: "alphixEventTag/manage"
      }
    },
    {
      name: "admin-tagging-deployment-event-create",
      path: "/admin/tagging/deployment/event/create",
      component: () => import("./views/Event/Create.vue"),
      meta: {
        allowed: "alphixEventTag/manage"
      }
    },
    {
      name: "admin-tagging-deployment-standard",
      path: "/admin/tagging/deployment/standard",
      component: () => import("./views/Delta/Config.vue"),
      meta: {
        allowed: "deltaTag/clientConfigure"
      }
    },
    {
      name: "admin-url-mapping",
      path: "/admin/url/mapping",
      component: () => import("./views/Configure/Url/Mapping.vue"),
      meta: {
        allowed: "analytics/urlDataMapping"
      }
    },
    {
      name:"configure-firmographic-groups",
      path:"/configure/firmographic/groups",
      component: () => import("./views/Configure/Firmographic/Groups.vue"),
      meta: {
        allowed: "client/alphaAnalyticsView"
      }
    },
    {
      name: "configure-milestones",
      path: "/configure/milestones",
      component: () => import("./views/Configure/Milestones/Manage.vue"),
      meta: {
        allowed: "analytics/actionsManage"
      }
    },
    {
      name: "delta-cookie",
      path: "/tag/cookie",
      component: () => import("./views/Delta/CookieDelta.vue"),
      meta: {
        allowed: "deltaTag/cookieCompare"
      }
    },
    {
      name: "insights-market-board",
      path: "/insights/market/board/:uuid",
      component: () => import("./views/Trends/Board.vue"),
      meta: {
        allowed: ["trends/view"]
      }
    },
    {
      name: "insights-market-board-list",
      path: "/insights/market/board-list",
      component: () => import("./views/Trends/BoardList.vue"),
      meta: {
        allowed: ["trends/view"]
      }
    },
    {
      name: "insights-market-cluster-list",
      path: "/insights/market/cluster-list",
      component: () => import("./views/Trends/ClusterList.vue"),
      meta: {
        allowed: ["trends/view"]
      }
    },
    {
      name: "insights-market-home",
      path: "/insights/market/home",
      component: () => import("./views/Trends/Home.vue"),
      meta: {
        allowed: ["trends/view"]
      }
    },
    {
      name: "terms",
      path: "/terms",
      component: () => import("./views/Terms.vue"),
    },
    {
      name: "error-404",
      path: "*",
      component: () => import("./views/Error/404.vue")
    },
    {
      name: "user-detail",
      path: "/user/detail",
      component: () => import("./views/User/Detail.vue")
    },
    {
      name: "user-terms",
      path: "/user/terms/:id",
      component: () => import("./views/User/Terms.vue"),
      meta: {
        allowed: "user/termsAdmin"
      }
    },
  ]
});

router.onError(error => {
  if (/loading chunk \d* failed./i.test(error.message)) {
    window.location.reload()
  }
});

router.beforeEach(async(to, from, next) => {
  if (to.name === 'analytics-report-view') {
    store.dispatch("alphixReport/setRoute", to);
  }
  next();
});
export default router;
