
export const configureTopLevelSections = {
  menuContainerName: "configure",
  sections: [
    {
      title: "Configure",
      nameRef: 'configure',
      icon: "https://storage.googleapis.com/static.alphix.com/image/icons/navigation/v2/configure-light.svg",
      links: [
        {
          title: "Firm Groups",
          links: null,
          routeName: 'configure-firmographic-groups',
          fAllowedPrivilege: ["client/alphaAnalyticsView"]
        },
        {
          title: "Milestones",
          links: null,
          routeName: 'configure-milestones',
          fAllowedPrivilege: ["analytics/actionsManage"]
        },
        {
          title: "IP Lists",
          links: null,
          routeName: 'configure-ip-list-manage',
          fAllowedPrivilege: ["client/manageIpList"]
        },
      ]
    }
  ]
}
