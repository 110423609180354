<template>
  <div v-if="showWarning">
    <v-alert
      v-if="!getSubscriptionStatus.agreementStatus.length"
      class="ma-10"
      type="warning"
    >
      No subscription has been added to the account. Access is limited until then.
    </v-alert>
    <div v-else>
      <div
        v-for="product of getSubscriptionStatus.agreementStatus"
        :key="product.productUuid"
      >
        <v-alert
          v-if="product.subscriptionStatus === 'expired'"
          class="ma-10"
          type="warning"
        >
          {{ product.productName }} subscription(s) expired. Please contact your account manager to arrange renewal.
        </v-alert>
        <v-alert
          v-if="product.subscriptionStatus === 'not-started'"
          class="ma-10"
          type="warning"
        >
          {{ product.productName }} subscription(s) not started. Please contact your account manager for more information.
        </v-alert>
      </div>
    </div>
  </div>
</template>

<script>
import Api from "@/connect/api";
import {
  mapActions,
  mapGetters
} from "vuex";

export default {
  name: "SubscriptionStatus",
  computed: {
    ...mapGetters("alphix", ["getSubscriptionStatus", "isFundamental", "getUser"]),
    showWarning() {
      return this.getSubscriptionStatus !== null && !this.isFundamental
    }
  },
  created() {
    this.lookupSubscriptionStatus();
  },
  methods: {
    ...mapActions('alphix', ['lookupSubscriptionStatus'])
  }
}
</script>

<style scoped>

</style>