<template>
  <v-app class="app">
    <select-client v-if="isSelectClient" />
    <page-loader v-else-if="loading" />
    <v-card
      v-else-if="restrictedPage"
      max-width="400"
      class="ma-auto my-10"
      rounded="lg"
    >
      <v-card-title>
        Restricted Area
      </v-card-title>
      <v-card-text>
        You are not allowed to enter this area at present. Please contact your company administrator to be granted
        additional access.
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        Code: {{ restrictedPage }}
      </v-card-actions>
    </v-card>
    <div 
      v-else
      class="sMainGrid"
    >
      <div class="sNav">
        <page-header-left-menu />
      </div>

      <div class="sHeader">
        <page-header />
      </div>
      
      <div class="sMain">
        <subscription-status />
        <v-main>
          <transition
            name="slide-fade"
            mode="out-in"
          >
            <router-view
              :key="$route.fullPath"
            />
          </transition>
        </v-main>
      </div>  
      <notifications />
    </div>
  </v-app>
</template>

<script>
import {
  mapActions,
  mapGetters
} from "vuex";
import SelectClient from "./views/SelectClient";
import PageHeader from "./components/Page/Header";
import PageLoader from "./components/Page/Loader";
import PageHeaderLeftMenu from "@/components/Page/Header/LeftMenu";
import SubscriptionStatus from "@/components/Client/Subscription/Status";

export default {
  components: {
    PageHeaderLeftMenu,
    PageLoader,
    PageHeader,
    SelectClient,
    SubscriptionStatus,
  },
  data() {
    return {
      restrictedPage: null,
      clientUuid: null
    }
  },
  computed: {
    ...mapGetters(
      "alphix",
      [
        "getAllowed",
        "getUser",
        "getClientUuid",
        "getUserSettingAlphixInterstitialRead",
      ]
    ),
    isSelectClient() {
      const subdomain = location.host.match(/[a-z0-9](?:[a-z0-9\-]{0,61}[a-z0-9])?/);
      return subdomain?.[0] === 'select-client'
    },
    loading() {
      return !this.getAllowed || !this.getUser || this.restrictedPage === null || !this.getClientUuid
        || !this.getUserSettingAlphixInterstitialRead
    }
  },
  watch: {
    $route() {
      if (this.getUser && !this.getUser.hasAcceptedTerms && this.$router.currentRoute.name !== 'terms') {
        this.$router.push({name: 'terms'});
      } else {
        this.restrictedPage = null;
        this.checkRestricted();
        this.setBreadCrumbList([]);
      }
    },
    getAllowed() {
      this.restrictedPage = null;
      this.checkRestricted();
    }
  },
  async created() {
    if (!this.isSelectClient) {
      this.lookupUser();
      this.lookupAllowed();
      this.lookupClientList();
      this.lookupClientPaidMedia();
      await this.lookUpUserSettingAlphixInterstitialRead();
      this.loadStonly();
    }
  },
  methods: {
    ...mapActions(
      "alphix", [
        "lookupAllowed",
        "lookupClientList",
        "lookupClientPaidMedia",
        "lookupUser",
        "setBreadCrumbList",
        "lookUpUserSettingAlphixInterstitialRead"
      ]
    ),
    checkRestricted() {
      if (this.getAllowed) {
        if (!this.$route?.meta?.allowed) {
          this.restrictedPage = false;
        } else {
          let access = this.$route?.meta?.allowed;

          this.restrictedPage = !this.fAllowed(access) ? access : false;
        }
      }
    },
    loadStonly() {
      window.STONLY_WID = "a84b6800-2bf4-11ee-a0af-0a52ff1ec764";

      !function (s,t,o,n,l,y,w,g,d,e){s.StonlyWidget||((d=s.StonlyWidget=function(){
        d._api?d._api.apply(d,arguments):d.queue.push(arguments)}).scriptPath=n,d.apiPath=l,d.sPath=y,d.queue=[],
        (g=t.createElement(o)).async=!0,(e=new XMLHttpRequest).open("GET",n+"version?v="+Date.now(),!0),
        e.onreadystatechange=function(){4===e.readyState&&(g.src=n+"stonly-widget.js?v="+
        (200===e.status?e.responseText:Date.now()),(w=t.getElementsByTagName(o)[0]).parentNode.insertBefore(g,w))},e.send())
      }(window,document,"script","https://stonly.com/js/widget/v2/");
      window.StonlyWidget._disableTargeting = true;
    }
  }
}
</script>

<style type="text/css" lang="scss">
@import "scss/fundamental-variables.scss";

body,
html {
  margin: 0;
  padding: 0;
  overflow: hidden;
  height: auto;
  font-family: $fontFamily;
  background-color: $colorLightest;

  .number-font-family {
    font-family: $number-font-family !important;
  }

  .app {
    min-height: 100vh;
    background-color: $colorLightest !important;

    .sMainGrid {
      width: 100vw;
      display: grid;
      grid-template-columns: minmax(234px, 1fr) 5.9fr;
      grid-template-rows: 60px auto;
      grid-column-gap: 0px;
      grid-row-gap: 0px;
      overflow-x: hidden;
      overflow-y: hidden;
      

      .sNav { 
        grid-area: 1 / 1 / 3 / 2;
      }

      .sHeader { 
        grid-area: 1 / 2 / 2 / 3;
      }

      .sMain { 
        grid-area: 2 / 2 / 3 / 3;
        width: 100%;
        max-width: 1650px;
        height: calc(100vh - 60px);
        padding: 20px;
        margin: 0 auto;
        display:flex;
        flex-grow: 1;
        overflow-y: scroll;
        flex-direction:column;

        &::-webkit-scrollbar {
          background-color: transparent;
          width: 6px;

        }

        &::-webkit-scrollbar-track {
          background-color: transparent;
        }

        &::-webkit-scrollbar-thumb {
          border-radius: 20px;
          border: 5px solid #DEE2EB;
          height: 10px;

        }
      }
    }
  }
}
</style>

<style type="text/css" lang="sass">
@import "scss/fundamental-variables.scss"

/** TRANSITIONS **/
.slide-fade-enter-active, .slide-fade-leave-active
  transition: 0.5s

.slide-fade-enter
  opacity: 0
  transform: translateX(-10px)

.slide-fade-leave-to
  opacity: 0
  transform: translateX(10px)

.slide-down-enter-active,
.slide-down-leave-active
  transition: 0.5s

.slide-down-enter
  opacity: 0
  transform: translateY(-10px)

.slide-down-leave-to
  opacity: 0
  transform: translateY(-10px)

.slide-left-enter-active,
.slide-left-leave-active
  transition: 0.1s ease-in

.slide-left-enter,
.slide-left-leave-to
  z-index: 999
  opacity: 0
  transform: translateX(-10px)

/** END TRANSITIONS **/

.ui-alert__body
  min-height: auto

.ui-textbox.is-active:not(.is-disabled)
  .ui-textbox__label-text
    color: $colorImpactDark

.ui-textbox.is-active:not(.is-disabled)
  .ui-textbox__input, .ui-textbox__textarea
    border-bottom: solid 1px $colorImpactDark

.ui-select.is-active:not(.is-disabled)
  .ui-select__label-text
    color: $colorImpactDark

.ui-select.is-active:not(.is-disabled)
  .ui-select__icon-wrapper .ui-icon
    color: $colorImpactDark

.ui-select.is-active:not(.is-disabled)
  .ui-select__display
    border-bottom: solid 1px $colorImpactDark

.ui-button--type-primary.ui-button--color-red
  background-color: $colorImpactDark

.ui-button--type-primary.ui-button--color-red:hover
  background-color: $colorImpact !important

.ui-button--type-primary.ui-button--color-accent
  background-color: $colorDark

.ui-button--type-primary.ui-button--color-accent:hover
  background-color: $colorMediumDark !important

.ui-button--type-primary.ui-button--color-default
  background-color: $colorMedium
  color: white

.ui-button--type-primary.ui-button--color-default:hover
  background-color: $colorMediumLight !important
.v-card.v-sheet
  border-radius: 10px!important
.v-data-table
  border-radius: 10px!important
</style>