import Api from "../connect/api";

// initial state
const state = {
  groupList: null,
  tagList: null,
}

const getters = {
  getGroupList: (state) => {
    return state.groupList;
  },
  getTagList: (state) => {
    return state.tagList;
  },
};

const actions = {
  lookupGroupList({commit, state}, relookup = false) {
    if (state.groupList === null || relookup) {
      Api.get(
          "firmographic/group",
          groupList => {
            commit("setGroupList", groupList);
          },
          () => {
            commit("setGroupList", false);
          }
      );
    }
  },
  lookupTagList({commit}, relookup = false) {
    if (state.tagList === null || relookup) {
      Api.get(
          "firmographic/group/tags",
          tagList => {
            commit("setTagList", tagList);
          },
          () => {
            commit("setTagList", false);
          }
      );
    }
  },
};

const mutations = {
  setGroupList(state, groupList) {
    state.groupList = groupList;
  },
  setTagList(state, tagList) {
    state.tagList = tagList;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
