<template>
  <div class="sLeftMenuFooter">

    <div
      class="sPageHeaderLeftMenuGroup"
    >
      <div
        v-if="menuMode !== 'topLevelSections'"
        @click="menuMode = 'topLevelSections'"
        class="my-1 sTitle"
      >
        <v-icon left>
          fas fa-light fa-angle-left
        </v-icon>
        <span>Back</span>
      </div>

      <div
        v-if="menuMode !== 'adminTopLevelSections' && this.showAdmin"
        @click="menuMode = 'adminTopLevelSections'"
        class="sTitle"
      >
        <img
          src="https://storage.googleapis.com/static.alphix.com/image/icons/navigation/v2/admin-light.svg"
          class="sCustomIcon"
        />
        <span>Admin</span>
      </div>

      <div
        v-if="menuMode !== 'configureTopLevelSections' && this.showConfigure"
        @click="menuMode = 'configureTopLevelSections'"
        class="sTitle"
      >
        <img
          src="https://storage.googleapis.com/static.alphix.com/image/icons/navigation/v2/configure-light.svg"
          class="sCustomIcon"
        />
        <span>Configure</span>
      </div>
    </div>

    <div class="footerBorder" />

    <div
      id="userMenu"
      ref="userMenu"
      class="sPageHeaderLeftMenuGroup mb-3"
      @mouseleave="closeUserMenu"
    >
      <div
        class="sTitle"
        @click="toggleUserMenu"
      >
        <v-avatar
          color="primary"
          size="24"
        >
          <span class="white--text ma-0 pa-0">{{ userInitials }}</span>
        </v-avatar>
        <span class="footer">{{ getName }}</span>
        <v-icon small>
          fal {{ userMenu ? 'fa-angle-up' : 'fa-angle-down' }}
        </v-icon>
      </div>
      <transition name="slide-down">
        <ul
          v-show="userMenu"
        >
          <li
            v-if="$route.path !== '/terms'"
            class="sChildTitle"
            @click="userDetail"
          >
            My account
          </li>
          <li
            class="sChildTitle" 
            @click="logout" 
          >
            Sign out
          </li>
        </ul>
      </transition>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Api from "@/connect/api";
import {getLoginRoute} from '@/core/domain';
import { getInitials } from '@fundamentalmedia/fundahelper/String';

export default {
  name: "LeftMenuFooter",
  props: {
    forceMenuMode: {
      type: String,
      default: 'topLevelSections',
    },
    showAdmin: {
      type: Boolean,
      default: false,
    },
    showConfigure: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      helpMenu: false,
      menuMode: this.forceMenuMode,
      userMenu: false,
    }
  },
  computed: {
    ...mapGetters("alphix", ["getName", "getId", "getSettings"]),
    ...mapGetters("subroute", ["getOnehelp"]),
    userInitials() {
      return getInitials(this.getName)[0];
    },
  },
  methods: {
    closeUserMenu() {
      if (this.userMenu === false) {
        return;
      }
      setTimeout(() => {
        this.$refs.userMenu.style.maxHeight = '48px'
      }, 350);
      this.userMenu = false
    },
    handleOneHelpClose() {
      this.helpMenu = !this.helpMenu
      if (this.getOnehelp.name === "submissionSuccess") {
        this.routeOnehelp("home")
      }
    },
    logout() {
      this.toggleUserMenu();
      Api.get("auth/logout", () => {
        window.location = getLoginRoute(false);
      });
    },
    toggleUserMenu() {
      // increases maxHeight to animate whole user menu up to allow dropdown menu to smoothly animate. 
      if (this.userMenu === false) {
        setTimeout(() => {
          this.userMenu = true
        }, 350);
        this.$refs.userMenu.style.maxHeight = '144px'
      } 
      else {
        setTimeout(() => {
          this.$refs.userMenu.style.maxHeight = '48px'
        }, 350);
        this.userMenu = false
      }
    },
    userDetail() {
      this.toggleUserMenu();
      this.$router.push({name: "user-detail"});
    },
  },
  watch: {
    menuMode: function() {
      this.$emit('menu-mode-updated', this.menuMode);
    }
  },
};
</script>

<style scoped lang="scss">
@import "../../../../scss/fundamental-variables.scss";

.sLeftMenuFooter {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.footerBorder {
  width: 100%;
  border-top: solid 1px #D8D8D8;
}

.lowerLink:hover {
  cursor:pointer;
}

i {
  font-size: 20px !important;
  color: $colorSlate;
}

.footer {
  font-size: 14px;
  color: $colorSlate;
}

#userMenu {
  height: 144px;
  max-height: 48px;
  transition: all 350ms ease-in;
}

.sPageHeaderLeftMenuGroup {
  width: 100%;
  padding: 8px 3px 16px 19px;

  ul {
    padding: 0;
    margin: 0;

    li {
      list-style: none;
    }
  }

  .sTitle, li {
    cursor: pointer;
  }

  .sTitle {
    height: 40px;
    display: flex;
    padding-left: 9px;
    padding-right: 10px;
    margin: 4px 0;
    align-items: center;
    border-radius: 8px;
    font-weight: 400;

    span {
      color:#454545;
      font-size: 15px;
      letter-spacing: 0;
      line-height: 16px;
      padding-left: 12px;
    }

    :nth-child(3) {
      margin-left: auto;
    }

    &.active {
      background-color: #F7F7F7;
    }

    .dropDownIcon i {
      font-size: 14px;
      font-weight: 900;
    }

    img {
      height: 20px;
    }

    &:hover {
      background-color:#EDEFF5;
    }
  }

  .sChildTitle {
    height:auto;
    min-height: 40px;
    margin-left: 25px;
    margin-left: 25px;
    padding-left: 9px;
    color: #666666;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 16px;
    display: flex;
    align-items: center;
    border-radius: 8px;
    margin-bottom: 8px;

    &:hover {
      background-color: #EDEFF5;
    }


    &.active {
      color: #314DFF;
      background-color: #EAEDFF;
    }
  }

}

</style>
