import dateRange from "@/helper/date/range";
import Api from "../connect/api";
import Vue from "vue";
import moment from "moment";
import router from "../router";

import {
  daysBetween,
  addDays,
  formatISODate,
  pastPeriod,
} from "@fundamentalmedia/fundahelper/Date";

const getDefaultState = () => {
  return {
    mcUuid: null,
    collection: null,
    collectionList: null,
    collectionSetting: {},
    dateRange: null,
  };
};

// initial state
const state = getDefaultState();

const getters = {
  getMcUuid: (state) => {
    return state.mcUuid;
  },
  getCollection: (state) => {
    return state.collection;
  },
  getCollectionList: (state) => {
    return state.collectionList;
  },
  getCollectionSetting: (state) => {
    return state.collectionSetting;
  },
  getDateRange: (state) => {
    return state.dateRange;
  },
  getPeriod12Month: (state) => {
    return pastPeriod(state.dateRange.start, state.dateRange.end, 12);
  },
};

const actions   = {
  resetCollection({commit}) {
    commit("setCollection", null);
  },
  setCollection({ commit }, collection) {
    commit('setCollection', collection);
    if (collection.setting) {
      commit("setCollectionSetting", collection.setting);
    }
  },
  setDateRange({ commit }, dateRangeStr) {
    const range = dateRange.rangeList().find(o => o.id === dateRangeStr);
    commit('setDateRange', range);
  },
};
const mutations = {
  resetState(state) {
    const newState = {
      ...getDefaultState(),
    };
    Object.assign(state, newState);
  },
  setMcUuid(state, mcUuid) {
    state.mcUuid = mcUuid;
  },
  setCollection(state, collection) {
    state.collection = collection;
  },
  setCollectionSetting(state, collectionSetting) {
    state.collectionSetting = collectionSetting;
    if (collectionSetting?.date_range) {
      state.dateRange = dateRange.range({
        type: collectionSetting.date_range
      });
    }
  },
  setDateRange(state, dateRange) {
    state.dateRange = dateRange;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
