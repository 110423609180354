import Api from "../connect/api";
export default function(action) {
  if (action === "page") {
    Api.post(
      "logger/page",
      {
        url: window.location.href,
        userAgent: navigator.userAgent
      },
      () => {},
      () => {},
      () => {}
    );
  }
}
