import Api from "@/connect/api";

const state = {
  clusterList: [],
  boardList: [],
  trackingSource: 'meta'
};

const getters = {
  getClusterList: (state) => {
    return state.clusterList;
  },
  getBoardList: (state) => {
    return state.boardList;
  },
  getTrackingSource: (state) => {
    return state.trackingSource;
  }
};

const actions = {
  lookupBoardList: ({ commit, getters }) => {
    return new Promise((resolve, reject) => {
      Api.get(
        "trends/topic/families",
        (response) => {
          commit("setBoardList", response);
          resolve(response);
        },
        (err) => {
          commit("setBoardList", []);
          reject(err);
        }
      );
    });
  },
  lookupClusterList: ({ commit, getters }) => {
    return new Promise((resolve, reject) => {
      Api.get(
        "trends/topic/cluster",
        (response) => {
          commit("setClusterList", response);
          resolve(response);
        },
        (err) => {
          commit("setClusterList", []);
          reject(err);
        }
      );
    });
  },
  setClusterList({ commit }, clusters) {
    commit("setClusterList", clusters);
  },
  setTrackingSource({ commit }, source) {
    commit("setTrackingSource", source);
  },
};

const mutations = {
  setBoardList: (state, boardList) => {
    state.boardList = boardList;
  },
  setClusterList: (state, clusterList) => {
    state.clusterList = clusterList;
  },
  setTrackingSource(state, source) {
    state.trackingSource = source;
  },
};

export default {
  actions,
  getters,
  mutations,
  namespaced: true,
  state,
};
