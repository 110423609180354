<template>
  <div
    ref="logo"
    :class="['logo_main', size]"
  >
    <div :class="loadingState">
      <div class="blue_box">
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
      </div>
      <div />
      <div />
    </div>
  </div>
</template>

<script>
export default {
  name: "PageLoader",
  props: {
    size: {
      type: String,
      default: 'Small' // Values Small, Medium, Large
    }
  },
  data() {
    return {
      loading: true,
      interval : null
    }
  },
  computed: {
    style() {
      const style = {};
      if (this.height) {
        style.height = `${this.height}px`
      }
      return style;
    },
    loadingState(){
      return this.loading? 'logo_inner':''
    }
  },
  mounted(){
    this.infiniteLoader();
},
beforeDestroy(){
    clearInterval(this.interval)
  },
  methods:{
    infiniteLoader(){
      this.interval = setInterval(()=>{
                        this.loading = false;
                        setTimeout(()=>{
                          this.loading = true;
                        }, 500)

                      }, 3500)
    }
  },


};
</script>


<style lang="scss" scoped>
.logo_main {
  line-height: 1.5!important;
  max-width: 350px;
  height: 350px;
  min-width: 350px;
  margin: 100px auto 0;
  box-sizing: border-box;
  padding-left: 20px;
  white-space: wrap!important;
  overflow: unset!important;
  &.Small{
    font-size: 10px!important;
    .logo_inner {
      position: relative;
      margin: 0 auto;
      width: 100%;
      height: 100%;
      max-width: 187px;
      max-height: 183px;
      left: 60px;
      top: 60px;
      overflow:hidden;
      display: block;
      transform: rotate(-45deg);
    }
    .blue_box {
      max-width: 43px;
      height: 133px;
      white-space: pre-wrap!important;
    }


    .logo_inner .blue_box div:nth-child(-n + 4),
    .logo_inner .blue_box div:nth-child(7) {
      overflow:hidden;
      display: block;
      height: 8px;
      width: 8px;
      background-color: #1D48D3;
      display: inline-block;
      margin-right: 7px;
    }

    .logo_inner .blue_box div:nth-child(-n + 4) {
      display: inline-block;
      margin-bottom: 6px;
      vertical-align: top;
      overflow:hidden;
    }
    .logo_inner .blue_box div:nth-child(7) {
      vertical-align: baseline;
      position: relative;
      bottom: 12px;
    }

    .logo_inner .blue_box div:nth-child(5),
    .logo_inner .blue_box div:nth-child(6) {
      height: 24px;
      width: 8px;
      background-color: #1D48D3;
      display: inline-block;
      margin-right: 7px;
      vertical-align: top;
    }
    .logo_inner .blue_box div:nth-child(3),
    .logo_inner .blue_box div:nth-child(6) {
      margin-right: 0;
    }

    .logo_inner > div:nth-child(2),
    .logo_inner > div:nth-child(3) {
      width: 53px;
      height: 9px;
      background: #3c3c4a;
    }

    .logo_inner > div:nth-child(3) {
      position: absolute;
      left: 45px;
      top: -0.286px;
      height: 54px;
      width: 9px;
      animation: 0.3s ease-out 0s 1 slideLine;
      animation-fill-mode: forwards;
      animation-delay: 1.6s;
      visibility: hidden;
    }

    .logo_inner > div:nth-child(2) {
      margin-top: -88px;
      animation-delay: 0.8s;
      position: relative;
      animation: 0.3s ease-out 0s 1 slideLine;
      animation-fill-mode: forwards;
      animation-delay: 1.9s;
      visibility: hidden;
    }
  }

  &.Medium{
    .logo_inner {
      position: relative;
      margin: 0 auto;
      width: 100%;
      height: 100%;
      max-width: 187px;
      max-height: 183px;
      left: 60px;
      top: 60px;
      transform: rotate(-45deg);
    }
    .blue_box {
      max-width: 60px;
      height: 133px;
      white-space: pre-wrap!important;
    }


    .logo_inner .blue_box div:nth-child(-n + 4),
    .logo_inner .blue_box div:nth-child(7) {
      height: 12px;
      width: 11px;
      background-color: #1D48D3;
      display: inline-block;
      margin-right: 11px;
    }

    .logo_inner .blue_box div:nth-child(-n + 4) {
      margin-bottom: 11px;
      vertical-align: top;
    }
    .logo_inner .blue_box div:nth-child(7) {
      vertical-align: baseline;
      position: relative;
      bottom: 18px;
    }

    .logo_inner .blue_box div:nth-child(5),
    .logo_inner .blue_box div:nth-child(6) {
      height: 34px;
      width: 11px;
      background-color: #1D48D3;
      display: inline-block;
      margin-right: 11px;
      vertical-align: top;
    }
    .logo_inner .blue_box div:nth-child(3),
    .logo_inner .blue_box div:nth-child(6) {
      margin-right: 0;
    }

    .logo_inner > div:nth-child(2),
    .logo_inner > div:nth-child(3) {
      width: 73px;
      height: 12px;
      background: #3c3c4a;
    }

    .logo_inner > div:nth-child(3) {
      position: absolute;
      left: 67px;
      top: -0.286px;
      height: 81px;
      width: 12px;
      animation: 0.3s ease-out 0s 1 slideLine;
      animation-fill-mode: forwards;
      animation-delay: 1.6s;
      visibility: hidden;
    }

    .logo_inner > div:nth-child(2) {
      margin-top: -64px;
      animation-delay: 0.8s;
      position: relative;
      animation: 0.3s ease-out 0s 1 slideLine;
      animation-fill-mode: forwards;
      animation-delay: 1.9s;
      visibility: hidden;
    }
  }

  &.Large{
    .logo_inner {
      position: relative;
      width: 100%;
      height: 100%;
      max-width: 187px;
      max-height: 183px;
      left: 60px;
      top: 60px;
      transform: rotate(-45deg);
    }
    .blue_box {
      max-width: 133px;
      height: 133px;
      white-space: pre-wrap!important;
    }

    .logo_inner .blue_box div:nth-child(-n + 4),
    .logo_inner .blue_box div:nth-child(7) {
      height: 26px;
      width: 25px;
      background-color: #1D48D3;
      display: inline-block;
      margin-right: 25px;
    }

    .logo_inner .blue_box div:nth-child(-n + 4) {
      margin-bottom: 25px;
      vertical-align: top;
    }
    .logo_inner .blue_box div:nth-child(7) {
      vertical-align: baseline;
      position: relative;
      bottom: 26px;
    }
    .logo_inner .blue_box div:nth-child(5),
    .logo_inner .blue_box div:nth-child(6) {
      height: 82px;
      width: 25px;
      background-color: #1D48D3;
      display: inline-block;
      margin-right: 25px;
      vertical-align: top;
    }
    .logo_inner .blue_box div:nth-child(3),
    .logo_inner .blue_box div:nth-child(6) {
      margin-right: 0;
    }
    .logo_inner > div:nth-child(2),
    .logo_inner > div:nth-child(3) {
      width: 163px;
      height: 25px;
      background: #3c3c4a;
    }
    .logo_inner > div:nth-child(3) {
      position: absolute;
      left: 162px;
      top: -0.286px;
      height: 183px;
      width: 25px;
      animation: 0.3s ease-out 0s 1 slideLine;
      animation-fill-mode: forwards;
      animation-delay: 1.6s;
      visibility: hidden;
    }
    .logo_inner > div:nth-child(2) {
      margin-top: 25px;
      animation-delay: 0.8s;
      position: relative;
      animation: 0.3s ease-out 0s 1 slideLine;
      animation-fill-mode: forwards;
      animation-delay: 1.9s;
      visibility: hidden;
    }
  }


  .logo_inner .blue_box div:nth-child(4) {
      animation: 0.3s ease-out 0s 1 slideInFromBottom;
      animation-delay: 0.3s;
      position: relative;
      visibility: hidden;
      animation-fill-mode: forwards;
  }
  .logo_inner .blue_box div:first-child {
    animation: 0.3s ease-out 0s 1 slideInFromBottom;
    animation-fill-mode: forwards;
    animation-delay: 0.6s;
    position: relative;
    visibility: hidden;
  }
  .logo_inner .blue_box div:nth-child(2) {
    position: relative;
    animation: 0.3s ease-out 0s 1 slideInFromLeft;
    animation-delay: 0.9s;
    animation-fill-mode: forwards;
    visibility: hidden;
  }
  .logo_inner .blue_box div:nth-child(3) {
    animation: 0.3s ease-out 0s 1 slideInFromLeft;
    animation-delay: 1.2s;
    animation-fill-mode: forwards;
    position: relative;
    visibility: hidden;
  }
  .logo_inner .blue_box div:nth-child(5) {
    animation: 0.3s ease-out 0s 1 slideLine;
    animation-fill-mode: forwards;
    animation-delay: 2.2s;
    visibility: hidden;
  }
  .logo_inner .blue_box div:nth-child(6) {
    animation: 0.3s ease-out 0s 1 slideLine;
    animation-fill-mode: forwards;
    animation-delay: 2.5s;
    visibility: hidden;
  }
  @keyframes slideInFromBottom {
    0% {
      transform: translateY(150%);
      visibility: visible;
    }
    100% {
      transform: translateY(0);
      visibility: visible;
    }
  }

  @keyframes slideInFromLeft {
    0% {
      transform: translateX(-150%);
      visibility: visible;
    }
    100% {
      transform: translate(0);
      visibility: visible;
    }
  }

  @keyframes slideLine {
    0% {
      visibility: visible;
      opacity: 0;
    }
    100% {
      visibility: visible;
      opacity: 1;
    }
  }

  @keyframes move1 {
        0%,
        10%,
        100%,
        50%,
        60%,
        80%,
        90% {
          opacity: 0;
        }
        20%,
        30%,
        40% {
          opacity: 1;
        }
  }
}
</style>
