import dateRange from "@/helper/date/range";
import Api from "../connect/api";
import Vue from "vue";
import moment from "moment";
import router from "../router";

import {
  daysBetween,
  addDays,
  formatISODate,
  pastPeriod,
} from "@fundamentalmedia/fundahelper/Date";

const getDefaultState = () => {
  return {
    aaList: [],
    acUuid: null,
    collection: null,
    collectionList: null,
    collectionSetting: null,
    dateRange: null,
    dimensionsList: null,
  };
};

const DEFAULT_DATE_RANGE = "last30Day",
      UNFILTERED_ROUTE = "unfiltered";

// initial state
const state = getDefaultState();

const getters = {
  getAaList: (state) => {
    return state.aaList;
  },
  getAcUuid: (state) => {
    return state.acUuid;
  },
  getCollection: (state) => {
    return state.collection;
  },
  getCollectionList: (state) => {
    return state.collectionList;
  },
  getCollectionSetting: (state) => {
    return state.collectionSetting;
  },
  getDateRange: (state) => {
    return state.dateRange;
  },
  // Get the same amount of time previously, so if date range is 1 month this will be the previous
  // month, if it's 3 months it will be the 3 months before that
  getDateRangePreviousPeriod: (state) => {
    const days                = daysBetween(state.dateRange.start, state.dateRange.end),
          endPreviousPeriod   = addDays(state.dateRange.start, -1),
          startPreviousPeriod = addDays(endPreviousPeriod, -1 * days);
    return {
      start: formatISODate(startPreviousPeriod),
      end: formatISODate(endPreviousPeriod),
    };
  },
  getDateRangePreviousYear: (state) => {
    const previousYearStart = new Date(state.dateRange.start);
    previousYearStart.setFullYear(previousYearStart.getFullYear() - 1);
    const previousYearEnd = new Date(state.dateRange.end);
    previousYearEnd.setFullYear(previousYearEnd.getFullYear() - 1);
    return {
      start: previousYearStart.toISOString().substring(0, 10),
      end: previousYearEnd.toISOString().substring(0, 10),
    };
  },
  getDimensionsList: (state) => {
    return state.dimensionsList ? state.dimensionsList : [];
  },
  getPeriod6Month: (state) => {
    return pastPeriod(state.dateRange.start, state.dateRange.end, 6);
  },
  getPeriod12Month: (state) => {
    return pastPeriod(state.dateRange.start, state.dateRange.end, 12);
  },
  getPeriod52Week: (state) => {
    const dateEnd = moment(state.dateRange.end);
    dateEnd.endOf("isoWeek");
    if (dateEnd.format("WW") === "53") {
      dateEnd.add(1, "week");
    }
    dateEnd.endOf("isoWeek");
    const dateStart = moment(dateEnd);
    dateStart.subtract(52, "weeks");
    if (dateStart.format("WW") === "53") {
      dateEnd.add(1, "week");
    }
    dateStart.startOf("isoWeek");

    const previousDateEnd   = moment(`${dateEnd.format("GGGG") - 1}-${dateEnd.format("WW")}`,
      "GGGG-WW").endOf("isoWeek");
    const previousDateStart = moment(`${dateStart.format("GGGG") - 1}-${dateStart.format("WW")}`,
      "GGGG-WW").startOf("isoWeek");

    return {
      dateEnd: dateEnd.format("YYYY-MM-DD"),
      dateStart: dateStart.format("YYYY-MM-DD"),
      previousDateEnd: previousDateEnd.format("YYYY-MM-DD"),
      previousDateStart: previousDateStart.format("YYYY-MM-DD"),
    };
  },
  getPeriodLastFullMonth: (state) => {
    return pastPeriod(
      moment(state.dateRange.start).subtract(1, "month").format("YYYY-MM-DD"),
      moment(state.dateRange.end).subtract(1, "month").format("YYYY-MM-DD"),
      1,
    );
  },
  isReady: (state) => {
    return state.collectionSetting !== null && state.dateRange !== null;
  },
};

const actions   = {
  applyTemporarySetting({
                          commit,
                          dispatch,
                          state,
                        }, {name, settings, uuid}) {
    if (uuid) {
      commit("setAcUuid", uuid)
    }
    commit("setCollection", {
      ...state.collection,
      dateRange: settings.date_range,
      name,
      settings,
      updated_at: new Date().toISOString(),
    });
    dispatch("setCollectionSetting", settings);
    dispatch("setDateRange", dateRange.range({type: settings.date_range}));
    dispatch("alphixData/resetState", null, {root: true});
  },
  removeTemporarySettings({ state, commit, dispatch }) {
    if (state.acUuid === UNFILTERED_ROUTE) {
      dispatch("applyUnfilteredCollection");
    } else {
      const collection = state.collectionList.find(cl => cl.uuid === state.acUuid);
      if (collection) {
        commit("setCollection", collection);
        dispatch("applyCollectionSettings", collection.settings);
      }
    }
  },
  lookupCollection({
                     commit,
                     dispatch,
                     state,
                   }, collectionUuid) {
    if (collectionUuid) {
      commit("setAcUuid", collectionUuid);
    }
    commit("setCollection", null);
    dispatch("setCollectionSetting", null);
    commit("setAaList", []);
    if (state.acUuid === UNFILTERED_ROUTE) {
      dispatch("applyUnfilteredCollection");
    } else {
      const collection = state.collectionList.find(cl => cl.uuid === state.acUuid);
      if (collection) {
        commit("setCollection", collection);
        Api.get(
          `alphalytics/collection/setting/${state.acUuid}`,
          (settings) => dispatch("applyCollectionSettings", settings)
        );
      }
    }
  },
  applyUnfilteredCollection({ commit, rootState, dispatch }) {
    commit("setCollection", {
      client_uuid: rootState.alphix.client.uuid,
      created_at: new Date().toISOString(),
      dateRange: DEFAULT_DATE_RANGE,
      name: "Unfiltered Collection",
      settings: {},
      updated_at: new Date().toISOString(),
      viewability: "client",
    });
    dispatch("setCollectionSetting", {});
    dispatch("alphixData/resetState", null, {root: true});
  },
  applyCollectionSettings({ dispatch }, settings) {
    dispatch("setCollectionSetting", settings);
    dispatch("setDateRange",
      dateRange.range({
        type: settings.date_range || DEFAULT_DATE_RANGE,
      }),
    );
    dispatch("alphixData/resetState", null, {root: true});
  },
  lookupCollectionList({
                         commit,
                         dispatch,
                         rootState,
                         state,
                       }, {collectionUuid, routeToCollection, doNotClear} = {}) {
    if (!doNotClear) {
      commit("setCollectionList", null);
    }
    Api.get(
      `alphalytics/collection`,
      collectionList => {
        commit("setCollectionList", collectionList);
        if (collectionUuid) {
          const collection = collectionList.find(c => c.uuid === collectionUuid);
          if (collection || collectionUuid === UNFILTERED_ROUTE) {
            commit("setAcUuid", collectionUuid);
            dispatch("lookupCollection");
            // dispatch("setDimensionsList");
            
            if (routeToCollection) {
              router.push({
                name: `analytics-overview`,
                params: {
                  acUuid: collection.uuid,
                  dateRange: collection.dateRange,
                },
              });
            }
          } else if (collectionUuid !== UNFILTERED_ROUTE) {
            router.push({name: "analytics-overview", params: {acUuid: "unfiltered", dateRange: "last30Day"}});
          }
        }
      },
      () => {
        commit("setCollectionList", []);
        if (collectionUuid === UNFILTERED_ROUTE) {
          commit("setAcUuid", collectionUuid);
          dispatch("lookupCollection");
        }
      },
    );
  },
  resetCollection({commit}) {
    commit("setCollection", null);
  },
  setDimensionsList({commit, state}, ){
    if (!state.dateRange || !state.collectionSetting) {
      return;
    }
    const { start, end } = state.dateRange;
    Api.post(
      `tag/reporting/dimension/list`,
      {
        domainId: state.collectionSetting.domain || [],
        endDate: end,
        startDate: start,
      },
      (customFilterList) => {
        commit("settingDimensionsList", customFilterList);
      },
      () => {
        commit("settingDimensionsList", []);
      }
    );
  },
  setRouteParams({
                   commit,
                   dispatch,
                   state,
                 }, params) {
    if (!params?.acUuid || !params?.dateRange) {
      commit("resetState");
      dispatch("alphixData/resetState", null, {root: true});
      dispatch("lookupCollectionList");
      return;
    }
    const [type, start, end] = params.dateRange.split("~")

    const dRange = dateRange.range({
      type,
      start,
      end,
    })

    if (state.acUuid !== params.acUuid) {
      commit("resetState");
      dispatch("setDateRange", dRange, )
      dispatch("lookupCollectionList", {collectionUuid: params.acUuid});
      return;
    }

    if (!state.dateRange || state.dateRange.type !== type || (type === 'custom' && (state.dateRange.start !== start || state.dateRange.end !== end))) {
      dispatch("setDateRange", dRange, )
      dispatch("alphixData/resetState", null, {root: true});
      return;
    }
  },
  setCollectionSetting({ commit, dispatch }, collectionSetting) {
    commit('setCollectionSetting', collectionSetting);
    if (collectionSetting !== null) {
      dispatch("setDimensionsList");
    }
  },
  setDateRange({ commit, dispatch }, dateRange) {
    commit('setDateRange', dateRange);
    dispatch("setDimensionsList");
  }
};
const mutations = {
  resetState(state) {
    const newState = {
      ...getDefaultState(),
    };

    Object.assign(state, newState);
  },
  setAaList(state, aaList) {
    state.aaList = aaList;
  },
  setAcUuid(state, acUuid) {
    state.acUuid = acUuid;
  },
  setCollection(state, collection) {
    state.collection = collection;
  },
  setCollectionList(state, collectionList) {
    state.collectionList = collectionList;
  },
  setCollectionSetting(state, collectionSetting) {
    if(!collectionSetting){
      state.collectionSetting = collectionSetting;
      return;
    }
    // if the path filter is old format (no subfilter) update it
    let originalValue = collectionSetting?.path_filter !== undefined ? JSON.parse(JSON.stringify(collectionSetting?.path_filter)): []

    if (Array.isArray(originalValue) && originalValue.length && !originalValue[0]?.subfilter) {
      let subfilter = [];
      let counter = 1;
      // handle old format
      for (let subitem of originalValue) {
        let newSubItem = {};
        newSubItem['subindex'] = counter;
        newSubItem['type'] = subitem.type;
        newSubItem['filter'] = subitem.filter;
        subfilter.push(newSubItem)
        counter+=1;
      }
      originalValue = [{
        index: 1,
        subfilter
      }]

      state.collectionSetting = {...collectionSetting, path_filter: originalValue}
    }else{
      state.collectionSetting = collectionSetting;
    }
  },
  setDateRange(state, dateRange) {
    state.dateRange = dateRange;
  },
  settingDimensionsList(state, dimensionsList) {
    state.dimensionsList = dimensionsList;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
