var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.topLevelSection)?_c('div',{staticClass:"sPageHeaderLeftMenuGroup"},[(_vm.topLevelSection.links)?_c('div',{staticClass:"sTitle menuWithChildrenHeader",class:{
      active: _vm.isExpanded,
      activeNotExpanded: !_vm.isExpanded && _vm.thisMenuLevel === 0,
      subMenu: _vm.navigationLevel >= 0,
      activeParent: _vm.thisMenuLevel === 0 && _vm.routeNameHierarchy[0] === _vm.topLevelSection.nameRef
    },on:{"click":_vm.expandMenu}},[(_vm.topLevelSection.icon)?_c('img',{staticClass:"sCustomIcon",attrs:{"src":_vm.iconSource}}):_vm._e(),_c('div',{staticClass:"text"},[_vm._v(" "+_vm._s(_vm.topLevelSection.title)+" ")]),_c('v-icon',{staticClass:"sExpanderIcon",attrs:{"small":""}},[_vm._v(" fal "+_vm._s(_vm.isExpanded ? 'fa-angle-up' : 'fa-angle-down')+" ")])],1):_vm._e(),_c('ul',{directives:[{name:"show",rawName:"v-show",value:(_vm.isExpanded),expression:"isExpanded"}]},_vm._l((_vm.topLevelSection.links),function(link){return _c('li',{directives:[{name:"show",rawName:"v-show",value:(!link.hide && link.authShow),expression:"!link.hide && link.authShow"}],key:link.title,staticClass:"sChildTitle",class:{
          active: _vm.isActive(link),
          sLink: !link.links,
          noLink: link.links
        },on:{"click":() => _vm.goTo(link)}},[(!link.links)?_c('span',[_vm._v(" "+_vm._s(link.title)+" ")]):_c('page-header-left-menu-navigation',{directives:[{name:"show",rawName:"v-show",value:(link.authShow),expression:"link.authShow"}],key:link.title,ref:"childMenu",refInFor:true,attrs:{"topLevelSection":link,"navigation-level":_vm.thisMenuLevel},on:{"expanded":function($event){return _vm.$emit('expanded')}}})],1)}),0)]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }