<template>
  <div
    v-if="topLevelSection"
    class="sPageHeaderLeftMenuGroup"
  >
    <div
      v-if="topLevelSection.links"
      class="sTitle menuWithChildrenHeader"
      @click="expandMenu"
      :class="{
        active: isExpanded,
        activeNotExpanded: !isExpanded && thisMenuLevel === 0,
        subMenu: navigationLevel >= 0,
        activeParent: thisMenuLevel === 0 && routeNameHierarchy[0] === topLevelSection.nameRef
      }"
    >
      <img
        v-if="topLevelSection.icon"
        :src="iconSource"
        class="sCustomIcon"
      />
      <div class="text">
        {{ topLevelSection.title }}
      </div>
      <v-icon
        class="sExpanderIcon"
        small
      >
        fal {{ isExpanded ? 'fa-angle-up' : 'fa-angle-down' }}
      </v-icon>
    </div>

    <ul
        v-show="isExpanded"
      >
        <li
          v-for="link of topLevelSection.links"
          v-show="!link.hide && link.authShow"
          :key="link.title"
          class="sChildTitle"
          :class="{
            active: isActive(link),
            sLink: !link.links,
            noLink: link.links
          }"
          @click="() => goTo(link)"
        >
          <span v-if="!link.links">
            {{ link.title }}
          </span>

          <page-header-left-menu-navigation
            v-else
            v-show="link.authShow"
            ref="childMenu"
            :key="link.title"
            :topLevelSection="link"
            :navigation-level="thisMenuLevel"
            @expanded="$emit('expanded')"
          />
        </li>
      </ul>

  </div>
</template>

<script>
export default {
  name: "PageHeaderLeftMenuNavigation",
  props: {
    expandImmediately: {
      type: Boolean,
      default: false
    },
    overrideClass: {
      type: String,
      default: ''
    },
    topLevelSection: {
      type: Object,
      default() {
        return {}
      }
    },
    navigationLevel: {
      type: Number,
      default() {
        return -1;
      }
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.setIsExpanded();
    });
  },
  computed: {
    iconSource() {
      if(
        this.isExpanded || (this.thisMenuLevel === 0 && this.routeNameHierarchy[0] === this.topLevelSection.nameRef)
      ) {
        return this.topLevelSection.icon.replace('-light', '-bold')
      } else {
        return this.topLevelSection.icon;
      }
    },
    routeNameHierarchy() {
      if (!this.$route || !this.$route.name) {
        return [];
      }
      return this.$route.name.split("-");
    }
  },
  data() {
    return {
      isExpanded: false,
      thisMenuLevel: this.navigationLevel+1,
    }
  },
  methods: {
    close() {
      this.isExpanded = false;
      for (const menu of this.$refs.childMenu || []) {
        menu.close();
      }
    },
    expandMenu() {
      this.$emit('expanded');
      this.isExpanded = !this.isExpanded
    },
    isActive(link) {
      return link.routeName === this.$route.name ||
        link?.alternativeRoutes?.includes(this.$route.name);
    },
    goTo(routeOptions) {

      if(!routeOptions?.routeName) return false;

      if (this.$route.name !== routeOptions.routeName) {

        if(routeOptions.externalUrl) {
          window.open(routeOptions.externalUrl, "_blank")
          return;
        }

        if (routeOptions.routeName.includes('analytics')) {
          this.$router.push({
            name: routeOptions.routeName,
            params: {
              acUuid: this.$route.params.acUuid || "unfiltered",
              dateRange: this.$route.params.dateRange || "last30Day"
            },
          });
        } else {
          this.$router.push({name: routeOptions.routeName});
        }
      }
    },
    setIsExpanded() {
      let expand = false;

      if (this.thisMenuLevel === 0 && this.routeNameHierarchy[0] === this.topLevelSection.nameRef) {
        expand = true;
      }
      if (this.thisMenuLevel === 1 && this.routeNameHierarchy[1] === this.topLevelSection.nameRef) {
        expand = true;
      }
      if (this.thisMenuLevel === 2 && this.routeNameHierarchy[2] === this.topLevelSection.nameRef) {
        expand = true;
      }

      if(expand === true) {
        this.isExpanded = true;
      } else {
        this.isExpanded = false;
      }
    }
  },
  watch: {
    $route: {
      handler: 'setIsExpanded',
      immediate: true
    },
  }



}
</script>

<style scoped lang="scss">
@import "@/scss/fundamental-variables.scss";

.sPageHeaderLeftMenuGroup {
  width: 100%;
  font-size:14px;

  ul {
    padding: 0;
    margin: 0;
    li {
      list-style: none;
    }
  }

  .sTitle, li {
    cursor: pointer;
  }

  .sTitle {
    height: 40px;
    display: flex;
    padding-right: 10px;
    padding-left: 10px;
    align-items: center;
    border-radius: 8px;
    font-weight: 400;

    div.text {
      color: $colorSlate;
      font-size: 14px;
      line-height: 16px;
      width:100%;
    }
    .sExpanderIcon {
      margin-left: auto;
    }
    &.active {
      background-color: #F7F7F7;
      font-weight: 500;
      margin-bottom:8px;
    }
    &.activeNotExpanded {
      margin-bottom:8px;
    }
    .sCustomIcon {
      height: 20px;
      padding-right:10px;
    }

  }

  .sTitle.subMenu:not(.active) {
    padding-left:0;
  }

  .sChildTitle {
    height:auto;
    min-height: 40px;
    margin-left: 25px;
    padding-left: 9px;
    color: #666666;
    letter-spacing: 0;
    line-height: 16px;
    display: flex;
    align-items: center;
    border-radius: 8px;
    margin-bottom: 8px;

    /*.sChildTitle:hover{
      background-color: #EDEFF5;
    }*/

    &.active {
      color: #314DFF;
      background-color: #EAEDFF;
    }
  }

  .sLink:hover {
    background-color: #EDEFF5;

  }
  .noLink{
    .sPageHeaderLeftMenuGroup{
      .menuWithChildrenHeader {
        margin-left: -9px;
        padding-left: 9px;
        padding-right: 9px;
        &.active:hover {
          background-color: #EDEFF5;
        }
        &:hover {
          background-color: #EDEFF5;
        }
      }
    }
    &.active {
        margin-left: -9px;
        padding-left: 9px;
        padding-right: 0;
      }

    &.active:hover {
      background-color: #EAEDFF;
    }
  }
  .menuWithChildrenHeader:hover {
    background-color:#EDEFF5;
  }
  .activeParent {
    background-color: #F7F7F7;
    font-weight: 500;
  }
}


</style>
















