<template>
  <v-autocomplete
    v-model="currentClientUuid"
    class="clientAutoComplete"
    :items="clientList"
    dense
    solo
    flat
    hide-details
    :disabled="clientList.length === 1"
    append-icon="mdi-dots-vertical"
    :menu-props="{
      rounded: 'sm'
    }"
  />
</template>

<script>
import {
  mapActions,
  mapGetters
} from "vuex";

export default {
  name: "PageHeaderClient",
  data() {
    return {
      currentClientUuid: null,
    }
  },
  computed: {
    ...mapGetters('alphix', ['getClientList', "getClientUuid"]),
    clientList() {
      return this.getClientList?.map(c => ({
        text: c.name,
        value: c.uuid
      }))
        ?.sort((a, b) => a?.text < b?.text ? -1 : 1) || [];
    }
  },
  watch: {
    currentClientUuid: {
      handler: "changeClient",
      immediate: true,
    }
  },
  created() {
    this.currentClientUuid = this.getClientUuid;
  },
  methods: {
    changeClient(clientUuid) {
      if (!this.getClientUuid || !clientUuid || this.getClientUuid === clientUuid) return;

      const client    = this.getClientList.find(c => c.uuid === clientUuid);
      const domain    = location.hostname.match(/[^.]*.(.*)/);
      window.location = `${location.protocol}//${client.subdomain}.${domain[1]}${location.port === "8064" ?
        ':8064' :
        ''}${location.pathname}`;
    }
  }
}
</script>

<style lang="scss">
.v-autocomplete__content.v-menu__content {
  -webkit-box-shadow: 0 1px 8px 0 #00000020 !important;
  box-shadow: 0 1px 8px 0 #00000020 !important;
}
</style>
<style scoped lang="scss">
.clientAutoComplete {

  :deep(.v-input__slot:hover) {
    background-color:#EDEFF5;
  }

  :deep(.v-input__slot) {
    padding: 0 4px 0 12px !important;
  }

  :deep(.v-menu__content) {
    padding: 8px !important;
  }

}

.v-autocomplete__content.v-menu__content .v-select-list {
  padding: 8px !important;
  border-radius: 8px !important;

  :deep(.v-list-item.v-list-item--link) {
    border-radius: 8px;
    margin: 2px 0;
  }

  :deep(.v-list-item .v-list-item__title) {
    font-weight: 400 !important;
  }

  :deep(.v-list-item:before) {
    border-radius: 8px !important;
  }

  :deep(.v-list-item.v-list-item--active.v-list-item--highlighted) {
    background-color: #D6DBFF;
    color: #314DFF;
    border-radius: 8px;
  }

  :deep(.v-list-item:before) {
    background-color: transparent;
  }

  :deep(.v-list-item:hover) {
    background-color: #EAEDFF;
    color: #314DFF;
  }
}

</style>