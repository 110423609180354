const LOGIN_DOMAIN = {
  LOCAL: {
    ALPHIX: "http://login.alphix.local:8069",
    PORTAL: "http://login.portal.local:8069",
  },
  ALPHA: {
    ALPHIX: "https://login.alphix-alpha.com",
    PORTAL: "https://login-alpha.fundamentalmedia.com",
  },
  BETA: {
    ALPHIX: "https://login.alphix-beta.com",
    PORTAL: "https://login-beta.fundamentalmedia.com",
  },
  DEMO: {
    ALPHIX: "https://login.alphix-demo.com",
    PORTAL: "https://login-demo.fundamentalmedia.com",
  },
  RELEASE: {
    ALPHIX: "https://login.alphix-release.com",
    PORTAL: "https://login-release.fundamentalmedia.com",
  },
  STAGING: {
    ALPHIX: "https://login.alphix-staging.com",
    PORTAL: "https://login-staging.fundamentalmedia.com",
  },
  PRODUCTION: {
    ALPHIX: "https://login.alphix.com",
    PORTAL: "https://login.fundamentalmedia.com",
  },
}

const HOMEPAGE_DOMAIN = {
  LOCAL: {
    ALPHIX: "http://select-client.alphix.local:8064",
    PORTAL: "http://portal.local:8061",
  },
  ALPHA: {
    ALPHIX: "https://select-client.alphix-alpha.com",
    PORTAL: "https://alpha-portal.fundamentalmedia.com",
  },
  BETA: {
    ALPHIX: "https://select-client.alphix-beta.com",
    PORTAL: "https://beta-portal.fundamentalmedia.com",
  },
  DEMO: {
    ALPHIX: "https://select-client.alphix-demo.com",
    PORTAL: "https://demo-portal.fundamentalmedia.com",
  },
  RELEASE: {
    ALPHIX: "https://select-client.alphix-release.com",
    PORTAL: "https://release-portal.fundamentalmedia.com",
  },
  STAGING: {
    ALPHIX: "https://select-client.alphix-staging.com",
    PORTAL: "https://staging-portal.fundamentalmedia.com",
  },
  PRODUCTION: {
    ALPHIX: "https://select-client.alphix.com",
    PORTAL: "https://portal.fundamentalmedia.com",
  },
}

module.exports = {HOMEPAGE_DOMAIN, LOGIN_DOMAIN}