<template>
  <div
    v-if="getBreadcrumbList.length"
    class="sPageBreadcrumb"
  >
    <ul>
      <li
        v-for="(itm, idx) of getBreadcrumbList"
        :key="itm.text"
      >
        <span
          :class="{enabled: $route.name !== itm.router.name}"
          @click="() => goTo(itm.router)"
        >
          {{ itm.text }}
        </span>
        <v-icon
          v-if="itm.text && idx + 1 < getBreadcrumbList.length"
          small
          class="fal fa-solid fa-angle-right mx-2"
        />
      </li>
    </ul>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "PageHeaderBreadcrumb",
  computed: {
    ...mapGetters('alphix', ["getBreadcrumbList"])
  },
  methods: {
    goTo(routeOptions) {
      if (this.$route.name !== routeOptions.name) {
        this.$router.push(routeOptions);
      }
    }
  }
};
</script>

<style scoped lang="scss">

.sPageBreadcrumb {
  display: flex;
  align-items: center;
  position: relative;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 16px;

  ul {
    display: flex;
    align-items: center;
    list-style: none;
    padding: 0 37px;

    li {
      cursor: default;
      color: #343434;

      span.enabled {
        cursor: pointer;
        color: #757575;
      }
    }

  }
}
</style>