export const adminTopLevelSections = {
  menuContainerName: "admin",
  sections: [
    {
      title: "Admin",
      nameRef: 'admin',
      icon: "https://storage.googleapis.com/static.alphix.com/image/icons/navigation/v2/admin-light.svg",
      links: [
        {
          title: "Users",
          routeName: "admin-subscriptions-users",
          fAllowedPrivilege: ["subscription/viewUser || subscription/manageUser"],
        },
        {
          title: "Subscriptions",
          routeName: 'admin-subscriptions-manage',
          fAllowedPrivilege: ["subscription/view"],
        },
        {
          title: "Tagging",
          nameRef: 'tagging',
          links: [
            {
              title: "Monthly Activity",
              routeName: "admin-tagging-activity",
              fAllowedPrivilege: ["deltaTag/viewBilling"],
            },
            {
              title: "Deployment",
              nameRef: 'deployment',
              links: [
                {
                  title: "Standard tags",
                  routeName: "admin-tagging-deployment-standard",
                  fAllowedPrivilege: ["deltaTag/clientConfigure"],
                },
                {
                  title: "Event tags",
                  routeName: "admin-tagging-deployment-event",
                  fAllowedPrivilege: ["alphixEventTag/manage"],
                },
              ],
            },
            {
              title: "Configure Domains",
              routeName: "admin-tagging-configure-domains",
              fAllowedPrivilege: ["client/configureDomain"],
            },
          ]
        },
        {
          title: "URL Mapping",
          routeName: "admin-url-mapping",
        },
      ]
    }
  ]
}
