import Api from "../connect/api";
import router from "../router";
import domainData from '../data/navigation/domain';
import solutionData from '../data/navigation/solution';
import {fAllowed} from "@/core/connect/mixin";

const getDefaultState = () => {
  return {
    allowed: null,
    breadcrumbList: [],
    client: null,
    clientList: null,
    clientPaidMedia: null,
    currentSolution: 'ALPHIX',
    geoList: null,
    settings: null,
    subscriptionStatus: null,
    user: null,
    userSettingAlphixInterstitialRead: null,
    userTermsAcceptedRecently: false,
  };
};

// initial state
const state = getDefaultState();

const getters = {
  dateFormat: function () {
    if (state.user && state.user.locale === "USA") return "MM/DD/YYYY";
    return "DD/MM/YYYY";
  },
  getBreadcrumbList() {
    return state.breadcrumbList;
  },
  getClient: (state) => {
    return state.client;
  },
  getClientUuid: (state) => {
    return state.client?.uuid || null;
  },
  getClientList: (state) => {
    return state.clientList;
  },
  getCurrentSolution: () => {
    return state.currentSolution;
  },
  getGeo: () => {
    return state.geoList;
  },
  getProductList: () => {
    return state.user.productList;
  },
  highChartsDateFormat: function () {
    if (state.user && state.user.locale === "USA") return "%m/%d/%Y";
    return "%d/%m/%Y";
  },
  featureData: () => {
    return {
      userId: state.user ? state.user.id : false
    };
  },
  getAllowed: function () {
    return state.allowed;
  },
  getClientPaidMedia: () => {
    return state.clientPaidMedia;
  },
  getEnv() {
    if (process.env.NODE_ENV === "development") {
      return 'local';
    }

    if (window.location.host.includes("staging")) {
      return "staging"
    }

    if (window.location.host.includes("release")) {
      return "release"
    }

    if (window.location.host.includes("beta")) {
      return "beta"
    }

    if (window.location.host.includes("alpha")) {
      return "alpha"
    }

    return "production";
  },
  getSettings() {
    return state.settings;
  },
  getSubscriptionStatus() {
    return state.subscriptionStatus;
  },
  getId: function () {
    return state.user ? state.user.id : false;
  },
  getName: function () {
    if (state.user === null) return "...";

    if (state.user === false) return "Unknown User";

    return state.user.name;
  },
  getUser: function () {
    return state.user;
  },
  getUserHasAcceptedTerms() {
    if (state.user)
      return state.user.hasAcceptedTerms ? state.user.hasAcceptedTerms : false;
  },
  getUserId: () => {
    return state.user.id;
  },
  getUserName: state => userId => {
    if (userId) {
      if (state.userNames === null) return "...";
      if (!state.userNames[userId]) return "Unknown";
      return state.userNames[userId];
    }

    if (state.user === null) return "...";

    if (state.user === false) return "Unknown User";

    return state.user.name;
  },

  getUserSettingAlphixInterstitialRead() {
    return state.userSettingAlphixInterstitialRead;
  },
  getUserTermsAcceptedRecently() {
    return state.userTermsAcceptedRecently;
  },
  isFundamental: function () {
    if (state.user === null) return false;

    if (state.user === false) return false;

    const emailParts = state.user.email.split("@");

    return emailParts[1] === "fundamentalmedia.com";
  }
};

const actions = {
  lookupAllowed({commit, rootState}) {
    Api.get(
      "auth/allowed",
      response => {
        commit("setAllowed", response);
      },
      () => {
        commit("setUser", false);
      }
    );
  },
  lookupClientList({commit}) {
    const notAllowedIntoClientUrl = domainData.HOMEPAGE_DOMAIN[getters.getEnv().toUpperCase()].ALPHIX;
    Api.get("client/list",
      clientList => {
        if (!clientList.find(c => c.current)) {
          window.location = notAllowedIntoClientUrl;
        } else {
          commit("setClientList", clientList);
        }
      },
      () => {
        window.location = notAllowedIntoClientUrl;
      }
    );
  },
  lookupClientPaidMedia({commit}) {
    Api.get("client/paid-media",
      () => {
        commit("setClientPaidMedia", true);
      },
      () => {
        commit("setClientPaidMedia", false);
      }
    );
  },
  lookupGeo({commit}) {
    if (!state.geoList?.length) {      
      Api.get("geo",
        geoList => {
            commit("setGeo", geoList);
        },
      );
    }
  },
  lookupSubscriptionStatus({commit}) {
    Api.get(
      `subscription/status`,
      (response) => {
        commit("setSubscriptionStatus", response);
      },
    );
  },
  lookupUser({commit}) {
    Api.get(
      "auth/current",
      response => {
        if (!response.hasAcceptedTerms && router.currentRoute.name !== 'terms') {
          router.push({name: 'terms'})
        }
        commit("setUser", response);
        const {
                client,
                fundamentalDepartment
              }     = response;
        var userOrg = fundamentalDepartment ? `Fundamental - ${fundamentalDepartment}` : client;
        if (typeof dataLayer !== "undefined" && Array.isArray(dataLayer)) {
          dataLayer.push({
            userOrg,
          });
        }
        if (typeof ga === "function") {
          ga('set', 'dimension2', userOrg);
          ga('send', 'pageview');
        }
      },
      () => {
        commit("setUser", false);
      }
    );
  },
  async lookUpUserSettingAlphixInterstitialRead({commit}) {
    return new Promise((resolve, reject) => {
      Api.get(
        "user/setting/alphixInterstitialRead",
        response => {
          commit("setUserSettingAlphixInterstitialRead", response);
          resolve(response);
        },
        error => {
          commit("setUserSettingAlphixInterstitialRead", []);
          reject(error);
        }
      );
    });
  },
  lookUpSettings({commit}) {
    Api.get(
      "setting",
      response => {
        commit("setSettings", response);
      }
    )
  },
  resetState({commit}) {
    commit("resetState");
  },
  setUserSettingAlphixInterstitialRead({commit}, data) {
    commit("setUserSettingAlphixInterstitialRead", data);
  },
  setBreadCrumbList({commit}, breadCrumbList) {
    commit("setBreadCrumbList", breadCrumbList);
  },

  //setUserSettingInterstitialRead(settingCode, callback)

  // add a vuex action for setUserSettingInterstitial(settingCode, callback)
  // which adds the code to the any existing setting, or creates a new array
  // if first and POSTs it off to user/setting. On success fire
  // lookupUserSetting(callback) passing the callback in and when that lookup
  // is successful it'll trigger the callback()



  userTermsAcceptedRecently({commit}) {
    commit("setUserTermsAcceptedRecently");
  }
};

const mutations = {
  resetState(state) {
    Object.assign(state, getDefaultState());
  },
  setAllowed(state, allowed) {
    state.allowed = allowed;
  },
  setBreadCrumbList(state, breadcrumbList) {
    state.breadcrumbList = breadcrumbList;
  },
  setClientList(state, clientList) {
    state.clientList = clientList;
    state.client     = clientList.find(c => c.current);
  },
  setClientPaidMedia(state, clientPaidMedia) {
    state.clientPaidMedia = clientPaidMedia;
  },
  setGeo(state, geoList) {
    state.geoList = geoList;
  },
  setSubscriptionStatus(state, subscriptionStatus) {
    state.subscriptionStatus = subscriptionStatus;
  },
  setUser(state, user) {
    state.user = user;
  },
  setSettings(state, settings) {
    state.settings = settings;
  },
  setUserNames(state, userNames) {
    state.userNames = userNames;
  },
  setUserSettingAlphixInterstitialRead(state, userSettingAlphixInterstitialRead) {
    state.userSettingAlphixInterstitialRead = userSettingAlphixInterstitialRead;
  },
  setUserTermsAcceptedRecently(state) {
    state.userTermsAcceptedRecently = true;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
