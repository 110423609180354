<template>
  <div class="sStickyHeader">
    <transition name="slide-down">
      <header>
        <breadcrumb />
      </header>
    </transition>
  </div>
</template>

<script>
import Breadcrumb from "@/components/Page/Header/Breadcrumb.vue";

export default {
  components: {
    Breadcrumb,
  },
};
</script>

<style scoped lang="sass">
@import "../../scss/fundamental-variables.scss"

.sStickyHeader
  position: -webkit-sticky
  position: sticky
  top: 0
  z-index: 100

header
  height: 60px
  display: flex
  color: #606266
  background-color: white
  border-bottom: solid 1px #D8D8D8
</style>
