export const topLevelSections = {
  menuContainerName: "main",
  sections:
    [
      {
        nameRef: 'analytics',
        title: "Site Analytics",
        icon: "https://storage.googleapis.com/static.alphix.com/image/icons/navigation/v2/analytics-light.svg",
        links: [
          {
            title: "Overview",
            routeName: "analytics-overview",
            fAllowedPrivilege: ["client/alphaAnalyticsView"],
          },
          {
            title: "Site traffic",
            routeName: "analytics-site-traffic",
            fAllowedPrivilege: ["client/alphaAnalyticsView"],
          },
          {
            title: "Audience acquisition",
            routeName: "analytics-audience-acquisition",
            fAllowedPrivilege: ["client/alphaAnalyticsView"],
          },
          {
            title: "Firmographic",
            routeName: "analytics-firmographic",
            fAllowedPrivilege: ["client/alphaAnalyticsView"],
          },
          {
            title: "Bot insights",
            routeName: "analytics-bot-insight",
            fAllowedPrivilege: ["client/alphaAnalyticsView"],
          },
          {
            title: "Site engagement",
            routeName: "analytics-site-engagement",
            fAllowedPrivilege: ["client/alphaAnalyticsView"],
          },
          {
            title: "Events",
            routeName: "analytics-events",
            fAllowedPrivilege: ["client/alphaAnalyticsView"],
          },
          {
            nameRef: 'marketing',
            title: "Marketing",
            fAllowedPrivilege: ["client/alphaAnalyticsView"],
            links: [
              {
                title: "Creative and sets",
                routeName: "analytics-marketing-creative-set",
                fAllowedPrivilege: ['creative/setView', 'creative/agencyView', 'client/productView'],
              },
              {
                title: "Attribution groups",
                routeName: "analytics-marketing-attribution-group",
                fAllowedPrivilege: ['client/attributionGroupView', 'client/flowView'],
                alternativeRoutes: ['analytics-marketing-attribution-group-detail'],
              },
              {
                title: "Attribution campaigns",
                routeName: "analytics-marketing-attribution-campaign",
                fAllowedPrivilege: ['client/attributionCampaignView'],
                alternativeRoutes: ['analytics-marketing-attribution-campaign-detail']
              },
              {
                title: "Flows",
                routeName: "analytics-marketing-flow",
                fAllowedPrivilege: ['client/flowView'],
                alternativeRoutes: ['analytics-marketing-flow-detail']
              },
              {
                title: "Creative agencies",
                routeName: "analytics-marketing-creative-agency",
                fAllowedPrivilege: ['creative/agencyView'],
                alternativeRoutes: ['analytics-marketing-creative-agency-detail']
              },
              {
                title: "Products",
                routeName: "analytics-marketing-product",
                fAllowedPrivilege: ['client/productView'],
                alternativeRoutes: ['analytics-marketing-product-detail']
              },
            ],
          },
          {
            title: "Reports",
            routeName: "analytics-report-list",
            fAllowedPrivilege: ["analytics/reportView"],
            alternativeRoutes: ["analytics-report-view"]
          },
        ],
      },
      {
        nameRef: 'insights',
        title: "Insights",
        icon: "https://storage.googleapis.com/static.alphix.com/image/icons/navigation/v2/insights-light.svg",
        links: [
          {
            nameRef: "market",
            title: "Market insights",
            links: [
              {
                title: "Browse Topics",
                routeName: "insights-market-home",
                fAllowedPrivilege: ["trends/view"],
              },
              {
                title: "Topic Boards",
                routeName: "insights-market-board-list",
                fAllowedPrivilege: ["trends/view"],
                alternativeRoutes: ["insights-market-board"],
              },
              {
                title: "Manage Topics",
                routeName: "insights-market-cluster-list",
                fAllowedPrivilege: ["trends/view"],
              },
            ],
          },
          {
            title: "Competitor insights",
            routeName: "externalFundamentalMonitor",
            externalUrl: "https://fundamentalmonitor.com",
          },
        ],
      },
      {
        nameRef: 'activation',
        title: "Activation",
        icon: "https://storage.googleapis.com/static.alphix.com/image/icons/navigation/v2/activation-light.svg",
        links: [
          {
            title: "AI asset builder",
            nameRef: "ai",
            links: [
              {
                title: "Discover",
                routeName: "activation-ai-home",
                fAllowedPrivilege: "vector/viewAd",
              },
              {
                title: "Find URL",
                routeName: "activation-ai-url",
                fAllowedPrivilege: "vector/viewAd",
              },
              {
                title: "Active URLs",
                routeName: "activation-ai-page",
                fAllowedPrivilege: "vector/viewAd",
              },
            ]
          },
        ]
      }
    ]
};







