<template>
  <page-loader v-if="clientList === null" />
  <v-alert
    v-else-if="!clientList"
    class="ma-10"
    type="warning"
  >
    You do not have access to any clients on the Alphix platform. Please request access from your company administrator.
  </v-alert>
  <div v-else>
    <v-card
      class="mx-auto my-10"
      style="max-height: 90vh; overflow-y: scroll;"
      :max-width="500"
      rounded="lg"
    >
      <v-card-title>Select Client</v-card-title>
      <v-card-text>
        <v-text-field
          v-model="search"
          label="Search clients"
          dense
          hide-details
        />
      </v-card-text>
      <v-list>
        <v-list-item
          v-for="client of filteredClientList"
          :key="client.uuid"
          @click="gotoClient(client)"
        >
          {{ client.name }}
        </v-list-item>
      </v-list>
    </v-card>
  </div>
</template>

<script>
import Api from "@/connect/api";
import PageLoader from "../components/Page/Loader";

export default {
  name: "SelectClient",
  components: {PageLoader},
  data() {
    return {
      clientList: null,
      search: "",
    };
  },
  computed: {
    filteredClientList() {
      return this.search
        ? this.clientList.filter(c => c.name.toLowerCase().includes(this.search.toLowerCase()))
        : this.clientList
    }
  },
  created() {
    Api.get("client/list",
      clientList => {
        if (clientList.length === 1) {
          this.gotoClient(clientList[0]);
        } else {
          this.clientList = clientList.sort((a, b) => a?.name < b?.name ? -1 : 1);
        }
      },
      () => {
        this.clientList = false;
      }
    );
  },
  methods: {
    gotoClient(client) {
      const domain    = location.hostname.match(/[^.]*.(.*)/);
      window.location = `${location.protocol}//${client.subdomain}.${domain[1]}${location.port === "8064" ?
        ':8064' :
        ''}`;
    }
  }
};
</script>

<style scoped>
.v-list{
  max-height:900px;
  overflow-y:auto
}
</style>
